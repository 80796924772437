import { decorate, observable, action } from 'mobx';
import http from './http';

class Framework {
  isLoggedIn = undefined;
  username = '';
  selected = '';
  currId = null;
  prevId = null;
  location = null;
  prevLocation = null;
  showModal = false;
  currentSession = '';

  checkIsLoggedIn = () => {
    http.get('/api/auth/is-logged-in').then(({ data }) => {
      this.isLoggedIn = data.code === 200;
    });
  }

  login = (username, password) => {
    var md5 = require('md5');
    let enPass = md5(password);
    http.post('/api/auth/login', {
      username: username,
      password: enPass,
    }).then(({ data }) => {
      if (data.code === 200) {
        this.isLoggedIn = true;
        this.username = username;
        this.currentSession = data.session;
      } else {
        this.showModal = true;
      }
    });
  }

  logout = () => {
    http.post('/api/auth/logout', {}).then(({ data }) => {
      this.isLoggedIn = false;
    });
    this.isLoggedIn = false;
  }
}

decorate(Framework, {
  showModal: observable,
  isLoggedIn: observable,
  username: observable,
  selected: observable,
  currId: observable,
  prevId: observable,
  location: observable,
  prevLocation: observable,
  currentSession: observable,
  checkIsLoggedIn: action,
});

const store = new Framework();

export default store;

window.store = store