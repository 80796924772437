import React from 'react';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import { storeCaseInfo } from './store_case_info';
import { makeForm } from './helper';
import {storeStaticList} from './store_static';


function calcTotalFees() {
  try {
    var agencyFeesAtOrigin = isNaN(parseFloat(storeCaseInfo.agencyFeesAtOrigin)) ? 0 : parseFloat(storeCaseInfo.agencyFeesAtOrigin)
    var agencyFeesInHK = isNaN(parseFloat(storeCaseInfo.agencyFeesInHK)) ? 0 : parseFloat(storeCaseInfo.agencyFeesInHK)
    storeCaseInfo.totalFeesPaid = agencyFeesAtOrigin + agencyFeesInHK
    return agencyFeesAtOrigin + agencyFeesInHK
  }
  catch (e) {
    console.log('calcTotalFees called  Exception ' + e)
    return 0
  }
}

const makePair = (...a) => a.map(item => ([item, item]))


const EmploymentDetailsForm = observer(class EmploymentDetailsForm extends React.Component {
  render() {
    const currencies = makePair(...storeStaticList.static_map['Currency']);
    const agencyNameAtOrigin = makePair(...storeStaticList.static_map['AgencyNameAtOrigin']);
    const agencyNameAtHK = makePair(...storeStaticList.static_map['AgencyNameAtHK']);
    const financeAgencyNameAtOrigin = makePair(...storeStaticList.static_map['FinanceAgencyAtOrigin']);
    const financeAgencyNameAtHK = makePair(...storeStaticList.static_map['FinanceAgencyAtHK']);
    return (
      <>

        {makeForm([
          [
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Employer Name', name: "employerName", props: { xs: 12, md: 4 } },
              { type: 'text', label: 'Employer Nationality', name: "employerNationality", props: { xs: 4, md: 2, } },
              { type: 'text', label: 'Household Members', name: "householdMembers", props: { xs: 2, md: 1, type: 'number' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Employer / Work Address', name: "workAddress", props: { xs: 12, md: 6, multiline: true}, },
              { type: 'text', label: 'Employer Contact No', name: 'employerContactNo', props: { xs: 4, md: 2, } }
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'No of Contracts with Current Employer', name: "noOfContractsWithCurrentEmployer", props: { xs: 4, md: 2, type: 'number' } },
              { type: 'text', label: 'No of HK Employers', name: "noOfHKEmployers", props: { xs: 4, md: 2, type: 'number' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Salary in Contract', name: "salaryInContract", props: { xs: 4, md: 2, type: 'number' } },
              { type: 'text', label: 'Salary Received', name: "salaryReceived", props: { xs: 4, md: 2, type: 'number' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
            ],
          ]
        ], storeCaseInfo)}
        <Typography variant="h6" gutterBottom>Recruitment Data</Typography>
        {makeForm([
          [
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'radioGroup', label: 'Recruited By', name: "recruitedBy", props: { xs: 8, md: 4, row: true, items: [['Agency', 'Agency'], ['Direct', 'Direct'], ['None', 'None']] } },
              //{ type: 'radioGroup', label: 'Direct Hire', name: "directHire", props: { xs: 4, md: 3 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'dropdown', label: 'Agency Name at Origin', name: "agencyNameAtOrigin", props: { xs: 6, md: 3 ,items:agencyNameAtOrigin} },
              { type: 'text', label: 'Agency Address at Origin', name: "agencyAddressAtOrigin", props: { xs: 12, md: 4, multiline: true, } },
              { type: 'dropdown', label: 'Finance Agency Name at Origin', name: "financeAgencyNameAtOrigin", props: { xs: 6, md: 3 ,items:financeAgencyNameAtOrigin} },
            ],
            { type: 'seperator', props: { xs: 12, md: 6 } },
            [
              { type: 'dropdown', label: 'Currency', name: "currency", props: { xs: 6, md: 1, items: currencies } },
              { type: 'text', label: 'Agency Fees at Origin (Local Currency)', name: "agencyFeesAtOriginCcy", props: { xs: 6, md: 3, type: 'number' } },
              { type: 'text', label: 'Agency Fees at Origin (HKD)', name: "agencyFeesAtOrigin", props: { xs: 6, md: 3, type: 'number' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'dropdown', label: 'Agency Name in HK', name: "agencyNameInHK", props: { xs: 6, md: 3 ,items:agencyNameAtHK} },
              { type: 'text', label: 'Agency Address in HK', name: "agencyAddressInHK", props: { xs: 12, md: 4, multiline: true} },
              { type: 'text', label: 'Agency Fees in HK (HKD)', name: "agencyFeesInHK", props: { xs: 4, md: 2, type: 'number' } },
              { type: 'dropdown', label: 'Finance Agency Name in HK', name: "financeAgencyNameInHK", props: { xs: 6, md: 3 ,items:financeAgencyNameAtHK} },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Manager Name at Origin', name: "managerNameAtOrigin", props: { xs: 6, md: 3 } },
              { type: 'text', label: 'Manager Contact at Origin', name: "managerContactAtOrigin", props: { xs: 4, md: 3 } },
              {
                type: 'text', label: 'Total Fees Paid (HKD)', name: "totalFeesPaid", props: {
                  xs: 4, md: 3, type: 'number',
                  disabled: true, error: true,
                  value: calcTotalFees()
                }
              }
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Manager Name in HK', name: "managerNameInHK", props: { xs: 6, md: 3 } },
              { type: 'text', label: 'Manager Contact in HK', name: "managerContactInHK", props: { xs: 4, md: 2 } },
            ],
          ]
        ], storeCaseInfo)}
      </>
    );
  }
});

export default EmploymentDetailsForm;