import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import http from './http';
import { storeCaseInfo } from './store_case_info';
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({});


function convertToEpoch(dateStr) {
  const x = Math.floor(new Date(dateStr).getTime() / 1000);
  if (x < 1000000) {
    throw Error('invalid data string');
  }
  return x;
}

class AdvancedSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectId: this.props.object || 1,
      items: [],
      selectedId: null,
      isAddingNew: false,
      textToBeAdded: '',
    };
  }

  changeCreatedAt = () => {
    const value = convertToEpoch(window.prompt("Please input date", ""));
    http.post('/api/case/set', {
      id: storeCaseInfo.id,
      attr: 'created_at',
      value: value,
      type: 'int',
    }).then(({ data }) => {
      storeCaseInfo.createdAt = value;
    })
  }

  deleteCase = () => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    if (!window.confirm('Are you sure? Are you sure?')) {
      return;
    }
    if (!window.confirm('Are you sure? Are you sure? Are you sure?')) {
      return;
    }

    http.post('/api/case/remove', {
      id: storeCaseInfo.id,
    }).then(() => {
      storeCaseInfo.clearStoreDetails();
      this.props.history.push('/query');
    })
  }

  getDate = (ts) => {
    return new Date(ts * 1000).toLocaleDateString("en-US");
  };

  render() {
    return storeCaseInfo.id !== null
      ? <Table >
        <TableBody>
          <TableRow>
            <TableCell>
              <Button onClick={this.deleteCase} variant="contained" color="primary">Remove this case</Button>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>
              <Button onClick={this.changeCreatedAt} variant="contained" color="primary">Set creation date</Button>
            </TableCell>
            <TableCell>This case is created at {this.getDate(storeCaseInfo.createdAt)}.</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
      : <span>You need to save this case first then use this panel.</span>;

  }
}

export default withRouter(observer(withStyles(styles)(AdvancedSetting)));