import axios from 'axios';
import querystring from 'querystring';

const constValues = {
  VER: '1',
};

const storage = window.localStorage

const sessionVars = {
  sessionStr: getStorage('session'),
}

function getStorage(key) {
  const k = key + constValues.VER;
  return storage.getItem(k) || '';
}

function setStorage(key, val) {
  const k = key + constValues.VER;
  storage.setItem(k, val);
}

function getCurrentBaseUrl() {
  const domain = document.domain === 'localhost' ? '13.114.104.182' : 'api.mfmw.hk';
  const res = window.location.protocol + '//' + domain + '/';
  return res;
}

axios.defaults.baseURL = getCurrentBaseUrl();
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use((config) => {
  if (config.method === 'post') {
    if (!config.data) {
      config.data = {}
    }
    if (sessionVars.sessionStr) {
      config.data.session = sessionVars.sessionStr
    }
    config.data = querystring.stringify(config.data)
  } else {
    if (!config.params) {
      config.params = {}
    }
    if (sessionVars.sessionStr) {
      config.params.session = sessionVars.sessionStr
    }
  }
  return config
}, (error) => {
  alert('error')
  return Promise.reject(error)
});

axios.interceptors.response.use(function (response) {
  if (response && response.data && response.data.session) {
    if (sessionVars.sessionStr !== response.data.session) {
      setStorage('session', response.data.session)
    }
    sessionVars.sessionStr = response.data.session
  }
  return response
}, function (error) {
  alert('error')
  return Promise.reject(error)
});

export default axios;