
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {observer} from 'mobx-react';
import store from './globals';

class PopupDialog extends Component {

  handleClose = (e) => {
    store.showModal = false;
  }

  render() {
    console.log('rendered');
    return <Dialog
      open={store.showModal}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{this.props.title || ''}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {this.props.body || ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary" autoFocus>OK</Button>
      </DialogActions>
    </Dialog>
  }
}

export default observer(PopupDialog);