import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {storeStaticList} from './store_static';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import PopupDialog from './popup_dialog';
import Grid from '@material-ui/core/Grid';
import StyledTableRow from './styled_table_row';
import StyledTableCell from './styled_table_cell'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { makeForm } from './helper';

const makePair = (...a) => a.map(item => ([item, item]))


const StaticListPanel = observer(class StaticListPanel extends React.Component {
  constructor(props) {
    super(props);
    storeStaticList.clearSearchList();
    storeStaticList.getList()
  }
  handleKeyChange(event) {
    storeStaticList.key = event.target.value;
  };

  handleValueChange(event) {
    storeStaticList.value = event.target.value;
  };

  handleNewKeyChange(event) {
    storeStaticList.newkey = event.target.value;
    console.log(event.target.value)
    console.log(storeStaticList.newkey)
  };

  handleNewValueChange(event) {
    storeStaticList.newvalue = event.target.value;
  };
  render() {
    const static_keys = makePair(...storeStaticList.keys)
    return (
      <>
        <Typography variant="h4" gutterBottom>
          Maintain Static Data
        </Typography>
        <Paper>
        {makeForm([
           [
            { type: 'dropdown', label: 'Static Type', name: "newkey", props: { xs: 12, md: 4, items: static_keys, required: true} },
            { type: 'text', label: 'Static Value', name: "newvalue", props: { xs: 12, md: 4, required: true} },
           ],
            { type: 'seperator', props: { xs: 12, md: 4 } }
          ], storeStaticList)
        }
         <Grid item xs={3}>
            <Button
              onClick={() => { storeStaticList.addStatic() }}
              variant="contained"
              color="primary"
            >Add Static Data</Button>
          </Grid>
          <PopupDialog title="MFMW" body="Static data has been added" />
        </Paper>
        <Paper>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              name="key"
              label="Static Type"
              select
              fullWidth
              onChange={this.handleKeyChange}
              InputLabelProps={{ shrink: true }}
              value={storeStaticList.key}
              SelectProps={{ native: true, MenuProps: {} }}
            >
              {
                static_keys.map((option, index) => <option key={index} value={option[1]}>{option[0]}</option>)
              }
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="value"
              label="Static Value"
              onChange={this.handleValueChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                storeStaticList.clearSearchList();
                storeStaticList.getList()
              }}
              variant="contained"
              color="primary"
            >Search Static Data</Button>
          </Grid>
          </Grid>
          {makeForm([ { type: 'seperator', props: { xs: 12, md: 4 } }],storeStaticList)}
          <PopupDialog title="MFMW" body="Remove Static Data Done" />
          <Table>
            <TableHead>
              <TableRow >
                <StyledTableCell>Static Type</StyledTableCell>
                <StyledTableCell>Static Value</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storeStaticList.list.map((row,i) => (
                <StyledTableRow key={`row-${i}`}>
                  <StyledTableCell>{row.key}</StyledTableCell>
                  <StyledTableCell>{row.value}</StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title='Delete Static'>
                <IconButton color="secondary" aria-label = 'delete' onClick={() => { storeStaticList.removeStatic(row.key, row.value)  }}><DeleteIcon></DeleteIcon></IconButton>
                </Tooltip>
                    </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <PopupDialog title="MFMW" body="Static data has been added" />
        </Paper>
      </>
    )
  };
})

export default StaticListPanel;