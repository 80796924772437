import React from 'react';
import { observer } from 'mobx-react';
import { storeCaseInfo } from './store_case_info';
import { makeForm } from './helper';
import {storeStaticList} from './store_static';

const makePair = (...a) => a.map(item => ([item, item]))

const caseStatus = makePair('Active', 'Closed', 'Inactive');
const genders = makePair('Gender', 'Female', 'Male', 'Others');
const status = makePair('NA', 'Married', 'Single', 'Seperated', 'Divorced', 'Widow');
const contractStatusList = makePair('NA', 'Ongoing', 'Finished', 'Terminated')
const paidWagesList = makePair('NA', 'Yes', 'No')
const noticeList = makePair('NA', 'Yes', 'No', 'Both')
const terminatedByList = makePair('NA', 'Employer', 'Employee')
const PersonalDetailsForm = observer(class PersonalDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    storeStaticList.clearSearchList();
    storeStaticList.getList();
    this.state = {
      id: null,
    }
  }

  handleChange = () => {
    return (event) => {
      let name = event.target.name
      storeCaseInfo[name] = event.target.value;
      if (name === 'placeOfOrigin') {
        if (storeCaseInfo.placeOfOrigin !== 'Other') {
          storeCaseInfo.otherCountry = ''
        }
      }
    };
  };

  isHKIdValid = () => {
    if ((typeof this.hkidError) === 'string') {
      return (this.hkidError.length > 0 ? true : false)
    }
    return false
  }

  setNext = (evt) => {
    storeCaseInfo.step2ButtonDisable = !storeCaseInfo.step2ButtonDisable;
  }

  render() {
    const countries = makePair(...storeStaticList.static_map['Country']);
    return (
      <React.Fragment>
        {makeForm([
          [
            { type: 'seperator', props: { xs: 8, md: 2 } },
            [
              {
                type: 'text', label: 'Case No', name: "id", props: {
                  xs: 8, md: 2, InputProps: {
                    readOnly: true,
                  }
                } 
              },
              {
                type: 'dropdown', label: 'Case Status', name: "caseStatus", props: {xs: 8, md: 2, items: caseStatus}
              },
              { type: 'text', label: 'Created At', name: "createdAt", props: { xs: 8, md: 2, type: 'date'} },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Last Name', name: "lastName", props: { xs: 12, md: 3 } },
              { type: 'text', label: 'Given Names', name: "givenNames", props: { xs: 12, md: 4, required: true} },
              { type: 'dropdown', label: 'Gender', name: "gender", props: { xs: 8, md: 2, items: genders, required: true} },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'dropdown', label: 'Civil Status', name: "civilStatus", props: { xs: 8, md: 2, items: status } },
              { type: 'dropdown', label: 'Place Of Origin', name: "placeOfOrigin", props: { xs: 8, md: 2, items: countries, required: true} },
              { type: 'text', label: 'Date Of Birth', name: "dateOfBirth", props: { xs: 8, md: 2, type: 'date', required: true} },
              {
                type: 'text', label: 'Age', name: "age", props: {
                  xs: 8, md: 1, InputProps: {
                    readOnly: true,
                  }
                }
              },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Passport No', name: "passportNo", props: { xs: 8, md: 2} },
              { type: 'text', label: 'HK ID', name: "hkid", props: { xs: 8, md: 2} },
              { type: 'text', label: 'ID-VC A No.', name: "idVCaNo", props: { xs: 4, md: 2 } },
              { type: 'text', label: 'Contract No', name: "contractNo", props: { xs: 4, md: 2 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'First Arrival Date', name: "firstArrivalDate", props: { xs: 8, md: 2, type: 'date' } },
              { type: 'text', label: 'Contract Start Date', name: "contractStartDate", props: { xs: 8, md: 2, type: 'date' } },
              { type: 'text', label: 'Contract End Date', name: "contractEndDate", props: { xs: 8, md: 2, type: 'date' } },
              { type: 'text', label: 'Visa Validity', name: "visaValidity", props: { xs: 8, md: 2, type: 'date' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'dropdown', label: 'Contract Status', name: "contractStatus", props: { xs: 8, md: 2, items: contractStatusList } },
              { type: 'dropdown', label: 'Terminated By', name: "contractTerminatedBy", props: { xs: 8, md: 2, items: terminatedByList } },
              { type: 'dropdown', label: 'Notice', name: "contractTerminationNotice", props: { xs: 8, md: 2, items: noticeList } },
              { type: 'dropdown', label: 'Paid Wages', name: "wagesPaidInLieuOfNotice", props: { xs: 8, md: 2, items: paidWagesList } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Employer Name', name: "employerName", props: { xs: 8, md: 4 } },
              { type: 'text', label: 'Salary in Contract', name: "salaryInContract", props: { xs: 8, md: 2, type: 'number' } },
              { type: 'text', label: 'Salary Received', name: "salaryReceived", props: { xs: 8, md: 2, type: 'number' } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Referred By', name: "referredBy", props: { xs: 8, md: 4 } },
              { type: 'text', label: 'Contact No', name: "referenceContactNo", props: { xs: 8, md: 2 } },
            ],   
            [
              { type: 'text', label: 'Contact Person', name: "localContact", props: { xs: 8, md: 4 } },
              { type: 'text', label: 'Contact No', name: "localContactNo", props: { xs: 8, md: 2 } },
            ],  
            [
              { type: 'text', label: 'Worker Contact No', name: "helperContactNo", props: { xs: 8, md: 4 } },
            ]
            // { type: 'seperator', props: { xs: 12, md: 4 } },
            // [
            //   { type: 'radioGroup', label: 'Contract Status', name: "contractStatus", props: { row: true, xs: 12, sm: 2, items: [['OnGoing', 'OnGoing'], ['Finished', 'Finished'], ['Terminated', 'Terminated']] } },
            //   { type: 'radioGroup', label: 'Terminated By', name: "contractTerminatedBy", props: { xs: 12, sm: 2, items: [['Employer', 'Employer'], ['Employee', 'Employee']] } },
            //   { type: 'radioGroup', label: 'Notice', name: "contractTerminationNotice", props: { xs: 12, sm: 2, items: [['Yes', 'Yes'], ['No', 'No'], ['Both', 'Both']] } },
            //   { type: 'radioGroup', label: 'Paid Wages', name: "wagesPaidInLieuOfNotice", props: { xs: 12, sm: 2, items: [['Yes', 'Yes'], ['No', 'No']] } },
            // ]
          ]
        ], storeCaseInfo)}
      </React.Fragment >
    );
  }
});

export default PersonalDetailsForm;