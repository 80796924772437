import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemComposition from './list_item';
import ListItemCompositionNoAdmin from './list_item_no_admin';
import SvgIcon from '@material-ui/core/SvgIcon';
import SearchCase from './search_case';
import UserPanel from './user_management';
import UserCreationPanel from './user_creation';
import StaticDataPanel from './static_data_management';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import store from './globals';
import InquiryForm from './inquiry';
import StatCase from './stat';
import MyProfile from './my_profile';
import storeCurrentUser from './current_user';
import { observer } from 'mobx-react';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2)
  },
  img: {
    margin: "0.5%"
  },
  text: {
    color: "black",
    fontSize: "110%"
  }
});

const Dashboard = observer(class Dashboard extends React.Component {
  componentDidMount() {
    storeCurrentUser.getUser();
  }
  state = {
    open: true,
    drawericon: ChevronLeftIcon,
    closeIconPath: "M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z",
  };

  handleDrawerCloseOpen = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
        closeIconPath: "M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z"
      });
    }
    else {
      this.setState({
        open: true,
        closeIconPath: 'M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z'
      });
    }
  };

  handleMenuClick = (v) => {
    return () => {
      store.selected = v;
    }
  }

  render() {
    const { classes } = this.props;
    return (<>
      <AppBar position="static">
        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              this.state.open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={window.location.origin + '/mfmw_logo_1.png'}
            width="4%" height="4%" className={classes.img}
            alt="MFMW Logo"
          />
          <Typography
            component="h1"
            variant="h6"
            //color="inherit"
            noWrap
            className={classes.title}
          >
            <Link
              to='/query'
              underline="none"
              style={{ textDecoration: 'none' }}
              className={classes.text}
            >
              SERVING MIGRANTS, ANYTIME, ANYWHERE
            </Link>
          </Typography>
          <Button onClick={store.logout} className={classes.text} size="medium">Logout</Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerCloseOpen}>
              <SvgIcon>
                <path d={this.state.closeIconPath} />
              </SvgIcon>
            </IconButton>
          </div>
          <Divider />
          {storeCurrentUser.role_id !== 1 ? <ListItemCompositionNoAdmin /> : <ListItemComposition />}
        </Drawer>
        <main className={classes.content}>
          <Switch>
            <Route path='/query' component={SearchCase} />
            <Route path='/admin/user' component={UserPanel} />
            <Route path='/admin/user_create' component={UserCreationPanel} />
            <Route path='/admin/static' component={StaticDataPanel} />
            <Route path='/inquiry/:selected/:id' component={InquiryForm} />
            <Route path='/inquiry/:selected' component={InquiryForm} />
            <Route path='/stat/case' component={StatCase} />
            <Route path='/my_profile' component={MyProfile} />
          </Switch>
        </main>
      </div>
    </>
    );
  }
})

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Dashboard));