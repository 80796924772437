
import { decorate, observable, action } from 'mobx';
import http from './http';

class StoreCaseList {
  list = [];
  id = null
  given_names = '';
  passport_no = '';
  hkid = '';

  showLoadMore = false;

  clearSearchList(){
    this.list = []
  }

  getList = (loadMore = false) => {
    const p = {};
    if (this.given_names) {
      p.given_names = this.given_names;
    }
    if(this.passport_no){
      p.passport_no = this.passport_no
    }
    if (this.id) {
      p.id = this.id;
    }
    if (this.hkid) {
      p.hkid = this.hkid;
    }
    if (loadMore && this.list.length) {
      p.cursor_id = this.list[this.list.length - 1].id;
    }
    http.get('/api/case/list', {
      params: p
    }).then(({ data }) => {
      if (data.code !== 200) {
        alert('error');
        return;
      }
      this.showLoadMore = !!data.data.length;
      if (loadMore) {
        this.list = [...this.list, ...data.data];
      } else {
        // new 
        this.list = data.data;
      }
    });
  }
}

decorate(StoreCaseList, {
  list: observable,
  id: observable,
  given_names: observable,
  passport_no: observable,
  hkid: observable,
  showLoadMore: observable,
  getList: action,
});

const storeCaseList = new StoreCaseList();

export default storeCaseList;