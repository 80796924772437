import { decorate, observable, computed } from 'mobx';
import http from './http';

const DEFAULT_DEC_VALUE = -1.0;

class CaseInfo {
  id = null;
  caseStatus = 'Open'
  createdAt = formatDate(new Date());
  // Personal Detail variables
  lastName = ''
  givenNames = ''
  gender = ''
  dateOfBirth = ''
  civilStatus = ''
  placeOfOrigin = ''
  otherCountry = ''
  passportNo = ''
  hkid = ''
  idVCaNo = ''
  contractNo = ''
  firstArrivalDate = ''
  contractStartDate = ''
  contractEndDate = ''
  employerName = ''
  salaryInContract = null
  salaryReceived = null
  contractStatus = null
  contractTerminatedBy = null
  contractTerminationNotice = null
  wagesPaidInLieuOfNotice = null
  visaValidity = ''
  localContact = ''
  localContactNo = ''
  referredBy = ''
  referenceContactNo = ''
  employerNationality = ''
  householdMembers = null
  workAddress = ''
  employerContactNo = ''
  noOfContractsWithCurrentEmployer = null
  noOfHKEmployers = null
  agencyNameAtOrigin = ''
  financeAgencyNameAtOrigin = ''
  agencyAddressAtOrigin = ''
  currency = ''
  managerNameAtOrigin = ''
  managerContactAtOrigin = ''
  agencyNameInHK = ''
  financeAgencyNameInHK = ''
  agencyAddressInHK = ''
  managerNameInHK = ''
  managerContactInHK = ''
  recruitedByAgency = false
  directHire = false
  recruitedBy = 'None'
  agencyFeesAtOrigin = null
  agencyFeesAtOriginCcy = null
  agencyFeesInHK = null
  helperContactNo = ''

  // Problem details check box fields
  monetaryClaim = false
  workAndLivingCondition = false
  accommodation = false
  shelterReferral = false
  illegalFeesCollection = false
  overcharging = false
  fraudulentLoan = false
  confiscation = false
  physicalAssault = false
  sexualHarassment = false
  accused = false
  scam = false
  personalLoan = false
  immigration = false
  familyIssue = false
  consulate = false
  otherPersonalProblems = false
  // Additional details
  caseDescription = ''
  chronology = []
  compensationComment = ''
  shelterName = ''

  // age = '--';
  wagesInArrears = null;
  wagesInLieuOfNotice = null;
  restDays = null;
  annualLeaveDays = null;
  statutoryHolidays = null;
  medicalFee = null;
  officialExpenseReimbursements = null;
  travelAllowance = null;
  airTicket = null;
  travelTaxInsurance = null;
  travelLocalTransport = null;
  travelAirportToDestination = null;
  others = null;
  lessClaims = null;

  // Case update page
  settlementAmountReceived = null;
  settlementAmountClaimed = null
  immigrationCaseDetails = ''
  policeCaseDetails = ''
  familyProblemDetails = ''
  personalLoanIssue = ''
  clientCurrentStatus = ''
  caseClosingDate = null
  additionalComments = ''

  //Attachments
  attachments = []

  loadCase = (id) => {
    fetch()
  };

  personalDetailsCheck = () => {
    let missingFields = []
    if (this.givenNames.length === 0) {
      missingFields.push('Given Names')
    }
    // if (this.hkid.length === 0 && this.passportNo.length === 0) {
    //   missingFields.push('Either Passport No or HKID')
    // }
    if (this.placeOfOrigin.length === 0) {
      missingFields.push('Place Of Origin')
    }
    if (this.gender.length === 0 || this.gender === 'Gender') {
      missingFields.push('Gender')
    }
    if (this.dateOfBirth.length === 0) {
      missingFields.push('DateOfBirth')
    }
    return missingFields
  }

  saveDetail = () => {
    const url = this.id === null ? '/api/case/create' : '/api/case/update';
    http.post(url, {
      'id': this.id,
      'created_at' : this.createdAt,
      'case_status' : this.caseStatus,
      'last_name': this.lastName,
      'given_names': this.givenNames,
      'gender': this.gender,
      'date_of_birth': this.dateOfBirth,
      'civil_status': this.civilStatus,
      'place_of_origin': this.placeOfOrigin,
      'other_country': this.otherCountry,
      'passport_no': this.passportNo,
      'hkid': this.hkid,
      'id_v_ca_no': this.idVCaNo,
      'contract_no': this.contractNo,
      'first_arrival_date' : this.firstArrivalDate,
      'contract_start_date': this.contractStartDate,
      'contract_end_date': this.contractEndDate,
      'employer_name': this.employerName,
      'salary_in_contract': this.salaryInContract !== null ? this.salaryInContract : DEFAULT_DEC_VALUE,
      'salary_received': this.salaryReceived !== null ? this.salaryReceived : DEFAULT_DEC_VALUE,
      'contract_status': this.contractStatus,
      'contract_terminated_by': this.contractTerminatedBy,
      'contract_termination_notice': this.contractTerminationNotice,
      'wages_paid_in_lieu_of_notice': this.wagesPaidInLieuOfNotice,
      'visa_validity' : this.visaValidity,
      'local_contact': this.localContact,
      'local_contact_no': this.localContactNo,
      'referred_by' :this.referredBy,
      'reference_contact_no': this.referenceContactNo,
      'employer_nationality': this.employerNationality,
      'household_members': this.householdMembers !== null ? this.householdMembers : DEFAULT_DEC_VALUE,
      'work_address': this.workAddress,
      'employer_contact_no': this.employerContactNo,
      'no_of_contracts_with_current_employer': this.noOfContractsWithCurrentEmployer !== null ? this.noOfContractsWithCurrentEmployer : DEFAULT_DEC_VALUE,
      'no_of_hk_employers': this.noOfHKEmployers !== null ? this.noOfHKEmployers : DEFAULT_DEC_VALUE,
      'agency_name_at_origin' : this.agencyNameAtOrigin,
      'finance_agency_at_origin': this.financeAgencyNameAtOrigin,
      'agency_address_at_origin': this.agencyAddressAtOrigin,
      'currency':this.currency,
      'manager_name_at_origin': this.managerNameAtOrigin,
      'manager_contact_at_origin': this.managerContactAtOrigin,
      'agency_name_in_hk': this.agencyNameInHK,
      'finance_agency_at_hk' : this.financeAgencyNameInHK,
      'agency_address_in_hk': this.agencyAddressInHK,
      'manager_name_in_hk': this.managerNameInHK,
      'manager_contact_in_hk' : this.managerContactInHK,
      'recruited_by_agency': this.recruitedByAgency ? 1 : 0 ,
      'direct_hire': this.directHire ? 1 : 0 ,
      'recruited_by' : this.recruitedBy,
      'agency_fees_at_origin': this.agencyFeesAtOrigin !== null ? this.agencyFeesAtOrigin : DEFAULT_DEC_VALUE,
      'agency_fees_at_origin_ccy' : this.agencyFeesAtOriginCcy !== null ? this.agencyFeesAtOriginCcy : DEFAULT_DEC_VALUE,
      'agency_fees_in_hk': this.agencyFeesInHK !== null ? this.agencyFeesInHK : DEFAULT_DEC_VALUE,
      'helper_contact_num': this.helperContactNo,

      'monetary_claim': this.monetaryClaim ,
      'work_and_living_condition': this.workAndLivingCondition ,
      'accommodation': this.accommodation ,
      'shelter_referral': this.shelterReferral ,
      'illegal_fees_collection': this.illegalFeesCollection ,
      'overcharging': this.overcharging ,
      'fraudulent_loan': this.fraudulentLoan ,
      'confiscation': this.confiscation ,
      'physical_assault': this.physicalAssault ,
      'sexual_harassment': this.sexualHarassment ,
      'accused': this.accused ,
      'scam': this.scam,
      'personal_loan': this.personalLoan ,
      'immigration': this.immigration ,
      'family_issue': this.familyIssue ,
      'consulate': this.consulate ,
      'other_personal_problems': this.otherPersonalProblems ,
      'case_description' : this.caseDescription,
      'compensation_comment' : this.compensationComment,
      'shelter_name' : this.shelterName,

      'chronology': this.chronology,
      //adding attributes from monetary claims to be saved
      'wages_in_arrears': this.wagesInArrears!== null ? this.wagesInArrears : DEFAULT_DEC_VALUE,
      'wages_in_lieu_of_notice':this.wagesInLieuOfNotice!== null ? this.wagesInLieuOfNotice : DEFAULT_DEC_VALUE,
      'rest_days':this.restDays!== null ? this.restDays : DEFAULT_DEC_VALUE,
      'annual_leave_days':this.annualLeaveDays!== null ? this.annualLeaveDays : DEFAULT_DEC_VALUE,
      'statutory_holidays':this.statutoryHolidays!== null ? this.statutoryHolidays : DEFAULT_DEC_VALUE,
      'medical_fee':this.medicalFee!== null ? this.medicalFee : DEFAULT_DEC_VALUE,
      'official_expense_reimbursements':this.officialExpenseReimbursements!== null ? this.officialExpenseReimbursements : DEFAULT_DEC_VALUE,
      'travel_allowance':this.travelAllowance!== null ? this.travelAllowance : DEFAULT_DEC_VALUE,
      'air_ticket':this.airTicket!== null ? this.airTicket : DEFAULT_DEC_VALUE,
      'travel_tax_insurance':this.travelTaxInsurance!== null ? this.travelTaxInsurance : DEFAULT_DEC_VALUE,
      'travel_local_transport':this.travelLocalTransport!== null ? this.travelLocalTransport : DEFAULT_DEC_VALUE,
      'travel_airport_to_destination':this.travelAirportToDestination!== null ? this.travelAirportToDestination : DEFAULT_DEC_VALUE,
      'others':this.others!== null ? this.others : DEFAULT_DEC_VALUE,
      'less_claims':this.lessClaims!== null ? this.lessClaims : DEFAULT_DEC_VALUE,
      //Case Update details
      'settlement_amount_received': this.settlementAmountReceived !== null ? this.settlementAmountReceived : DEFAULT_DEC_VALUE,
      'settlement_amount_claimed': this.settlementAmountClaimed !== null ? this.settlementAmountClaimed : DEFAULT_DEC_VALUE,
      'immigration_case_details' : this.immigrationCaseDetails,
      'police_case_details' : this.policeCaseDetails,
      'family_problems_details' : this.familyProblemDetails,
      'personal_loan_issue' : this.personalLoanIssue,
      'client_current_status' : this.clientCurrentStatus,
      'case_closing_date' : this.caseClosingDate,
      'additional_comments' : this.additionalComments,
    }).then(({ data }) => {
      if(this.id === null){
        this.id = data.data;
      }
    })
  }

  clearStoreDetails = () => {
    this.id =  null;
    this.CaseStatus = 'Open'
    this.createdAt = formatDate(new Date());
    // Personal Detail variables
    this.lastName = '';
    this.givenNames = '';
    this.gender = ''
    this.dateOfBirth = ''
    this.civilStatus = ''
    this.placeOfOrigin = ''
    this.otherCountry = ''
    this.passportNo = ''
    this.hkid = ''
    this.idVCaNo = ''
    this.contractNo = ''
    this.firstArrivalDate = ''
    this.contractStartDate = ''
    this.contractEndDate = ''
    this.employerName = ''
    this.salaryInContract = null
    this.salaryReceived = null
    this.contractStatus = null
    this.contractTerminatedBy = null
    this.contractTerminationNotice = null
    this.wagesPaidInLieuOfNotice = null
    this.visaValidity = ''
    this.localContact = ''
    this.localContactNo = ''
    this.referredBy = ''
    this.referenceContactNo = ''
    this.employerNationality = ''
    this.householdMembers = null
    this.workAddress = ''
    this.employerContactNo = ''
    this.noOfContractsWithCurrentEmployer = null
    this.noOfHKEmployers = null
    this.agencyNameAtOrigin = ''
    this.financeAgencyNameAtOrigin = ''
    this.agencyAddressAtOrigin = ''
    this.currency = ''
    this.managerNameAtOrigin = ''
    this.managerContactAtOrigin = ''
    this.agencyNameInHK = ''
    this.financeAgencyNameInHK = ''
    this.agencyAddressInHK = ''
    this.managerNameInHK = ''
    this.managerContactInHK = ''
    this.recruitedByAgency = false
    this.directHire = false
    this.recruitedBy = 'None'
    this.agencyFeesAtOrigin = null
    this.agencyFeesAtOriginCcy = null
    this.agencyFeesInHK = null
    this.helperContactNo = ''
    // Problem details check box fields
    this.monetaryClaim = false
    this.workAndLivingCondition = false
    this.accommodation = false
    this.shelterReferral = false
    this.illegalFeesCollection = false
    this.overcharging = false
    this.fraudulentLoan = false
    this.confiscation = false
    this.physicalAssault = false
    this.sexualHarassment = false
    this.accused = false
    this.scam = false
    this.personalLoan = false
    this.immigration = false
    this.familyIssue = false
    this.consulate = false
    this.otherPersonalProblems = false
    // Additional details
    this.caseDescription = ''
    this.chronology = []
    this.compensationComment = ''
    this.shelterName = ''

    // this.age = '--';
    this.salaryPerDay = null;
    this.restDays = null;
    this.annualLeaveDays = null;
    this.wagesInArrears = null;
    this.wagesInLieuOfNotice = null;
    this.statutoryHolidays = null;
    this.medicalFee = null;
    this.officialExpenseReimbursements = null;
    this.travelAllowance = null;
    this.airTicket = null;
    this.travelTaxInsurance = null;
    this.travelLocalTransport = null;
    this.travelAirportToDestination = null;
    this.others = null;
    this.lessClaims = null;

    //Case update fields
    this.settlementAmountReceived = null;
    this.settlementAmountClaimed = null;
    this.immigrationCaseDetails = '';
    this.policeCaseDetails = '';
    this.familyProblemDetails ='';
    this.personalLoanIssue = '';
    this.clientCurrentStatus = '';
    this.caseClosingDate = null;
    this.additionalComments = '';
    // Clear attachments
    this.attachments = []
  }


  setDetailsFromServer = (data) => {
    //Personal Details form details
    this.createdAt = formatDate(getDate(data.created_at));
    this.id = data.id ? Number(data.id) : null
    this.caseStatus = data.case_status ? data.case_status : 'Open'
    this.lastName = data.last_name
    this.givenNames = data.given_names
    this.gender = data.gender
    this.dateOfBirth = data.date_of_birth
    this.civilStatus = data.civil_status
    this.placeOfOrigin = data.place_of_origin
    this.passportNo = data.passport_no
    this.hkid = data.hkid
    this.idVCaNo = data.id_v_ca_no
    this.contractNo = data.contract_no
    this.firstArrivalDate = data.first_arrival_date
    this.contractStartDate = data.contract_start_date
    this.contractEndDate = data.contract_end_date
    this.employerName = data.employer_name
    this.salaryInContract = Number(data.salary_in_contract) === DEFAULT_DEC_VALUE ? null : Number(data.salary_in_contract)
    this.salaryReceived = Number(data.salary_received) === DEFAULT_DEC_VALUE ? null : Number(data.salary_received)
    this.contractStatus = data.contract_status
    this.contractTerminatedBy = data.contract_terminated_by
    this.contractTerminationNotice = data.contract_termination_notice
    this.wagesPaidInLieuOfNotice = data.wages_paid_in_lieu_of_notice
    this.visaValidity = data.visa_validity
    this.localContact = data.local_contact
    this.localContactNo = data.local_contact_no
    this.referredBy = data.referred_by
    this.referenceContactNo = data.reference_contact_no
    this.employerNationality = data.employer_nationality
    this.householdMembers = Number(data.household_members) === DEFAULT_DEC_VALUE ? null : Number(data.household_members)
    this.workAddress = data.work_address
    this.employerContactNo = data.employer_contact_no
    this.noOfContractsWithCurrentEmployer = Number(data.no_of_contracts_with_current_employer) === DEFAULT_DEC_VALUE ? null : Number(data.no_of_contracts_with_current_employer)
    this.noOfHKEmployers = Number(data.no_of_hk_employers) === DEFAULT_DEC_VALUE ? null : Number(data.no_of_hk_employers)
    this.agencyNameAtOrigin = data.agency_name_at_origin
    this.financeAgencyNameAtOrigin = data.finance_agency_at_origin
    this.agencyAddressAtOrigin = data.agency_address_at_origin
    this.currency = data.currency
    this.managerNameAtOrigin = data.manager_name_at_origin
    this.managerContactAtOrigin = data.manager_contact_at_origin
    this.agencyNameInHK = data.agency_name_in_hk
    this.financeAgencyNameInHK = data.finance_agency_at_hk
    this.agencyAddressInHK = data.agency_address_in_hk
    this.managerNameInHK = data.manager_name_in_hk
    this.managerContactInHK = data.manager_contact_in_hk
    this.recruitedByAgency = data.recruited_by_agency ? 1:0
    this.directHire = data.direct_hire ? 1:0
    this.recruitedBy = data.recruited_by
    this.agencyFeesAtOrigin  = Number(data.agency_fees_at_origin) === DEFAULT_DEC_VALUE ? null : Number(data.agency_fees_at_origin)
    this.agencyFeesAtOriginCcy = Number(data.agency_fees_at_origin_ccy) === DEFAULT_DEC_VALUE ? null : Number(data.agency_fees_at_origin_ccy)
    this.agencyFeesInHK = Number(data.agency_fees_in_hk) === DEFAULT_DEC_VALUE ? null : Number(data.agency_fees_in_hk)
    this.helperContactNo = data.helper_contact_num

    this.monetaryClaim = data.monetary_claim ? 1: 0;
    this.workAndLivingCondition = data.work_and_living_condition ? 1 : 0;
    this.accommodation = data.accommodation ? 1 : 0;
    this.shelterReferral = data.shelter_referral ? 1 : 0;
    this.illegalFeesCollection = data.illegal_fees_collection ? 1 : 0; 
    this.overcharging = data.overcharging ? 1 : 0;
    this.fraudulentLoan = data.fraudulent_loan ? 1 : 0;
    this.confiscation = data.confiscation ? 1 : 0;
    this.physicalAssault = data.physical_assault ? 1 : 0;
    this.sexualHarassment = data.sexual_harassment ? 1 : 0;
    this.accused = data.accused ? 1 : 0;
    this.scam = data.scam ? 1 : 0;
    this.personalLoan = data.personal_loan ? 1 : 0; 
    this.immigration = data.immigration ? 1 : 0;
    this.familyIssue = data.family_issue ? 1 : 0;
    this.consulate = data.consulate ? 1 : 0;
    this.otherPersonalProblems = data.other_personal_problems ? 1 : 0;

    this.caseDescription = data.case_description
    this.chronology = data.chronology ? data.chronology : []
    this.compensationComment = data.compensation_comment
    this.shelterName = data.shelter_name

    //setting fields for monetary claims
    this.wagesInArrears = Number(data.wages_in_arrears)=== DEFAULT_DEC_VALUE ? null : Number(data.wages_in_arrears);
    this.wagesInLieuOfNotice = Number(data.wages_in_lieu_of_notice) === DEFAULT_DEC_VALUE ? null : Number(data.wages_in_lieu_of_notice)
    this.restDays = Number(data.rest_days) === DEFAULT_DEC_VALUE ? null : Number(data.rest_days)
    this.annualLeaveDays = Number(data.annual_leave_days) === DEFAULT_DEC_VALUE ? null : Number(data.annual_leave_days)
    this.statutoryHolidays = Number(data.statutory_holidays) === DEFAULT_DEC_VALUE ? null : Number(data.statutory_holidays)
    this.medicalFee = Number(data.medical_fee) === DEFAULT_DEC_VALUE ? null : Number(data.medical_fee)
    this.officialExpenseReimbursements = Number(data.official_expense_reimbursements) === DEFAULT_DEC_VALUE ? null : Number(data.official_expense_reimbursements)
    this.travelAllowance = Number(data.travel_allowance) === DEFAULT_DEC_VALUE ? null : Number(data.travel_allowance)
    this.airTicket = Number(data.air_ticket) === DEFAULT_DEC_VALUE ? null : Number(data.air_ticket)
    this.travelTaxInsurance = Number(data.travel_tax_insurance) === DEFAULT_DEC_VALUE ? null : Number(data.travel_tax_insurance)
    this.travelLocalTransport = Number(data.travel_local_transport) === DEFAULT_DEC_VALUE ? null : Number(data.travel_local_transport)
    this.travelAirportToDestination = Number(data.travel_airport_to_destination) === DEFAULT_DEC_VALUE ? null : Number(data.travel_airport_to_destination)
    this.others = Number(data.others) === DEFAULT_DEC_VALUE ? null : Number(data.others)
    this.lessClaims = Number(data.less_claims) === DEFAULT_DEC_VALUE ? null : Number(data.less_claims)

    //case update details
    this.settlementAmountReceived = Number(data.settlement_amount_received) === DEFAULT_DEC_VALUE ? 0 : Number(data.settlement_amount_received)
    this.settlementAmountClaimed = Number(data.settlement_amount_claimed) === DEFAULT_DEC_VALUE ? 0 : Number(data.settlement_amount_claimed)
    this.immigrationCaseDetails = data.immigration_case_details
    this.policeCaseDetails = data.police_case_details
    this.familyProblemDetails = data.family_problems_details
    this.personalLoanIssue = data.personal_loan_issue
    this.clientCurrentStatus = data.client_current_status
    this.caseClosingDate = data.case_closing_date
    this.additionalComments = data.additional_comments
  }

  setAttachmentsFromServer = (data) => {
  this.attachments = data
  }

  getList = () => {
    http.get('/api/case/list').then(({ data }) => {
      console.log(data);
    })
  }

  getDetail = (id) => {
    http.get('/api/case/detail', { params: { id: id } }).then(({ data }) => {
      console.log(data);
    })
  }

  newCase = () => {
    http.post('/api/case/create', {
      'last_name': '2222222',
    }).then(({ data }) => {
      console.log(data);
    })
  }

  get age() {
    try {
      if (this.dateOfBirth) {
        let diff_ms = Date.now() - new Date(this.dateOfBirth).getTime();
        let diff_dt = new Date(diff_ms);
        let age = diff_dt.getUTCFullYear() - 1970;
        return age >= 0 ? age : '--';
      }
    } catch (e) {
      console.log('calc age called Exception ' + e);
    }
    return '--';
  }
}

//Hope easy way to format date in Java script - 
function formatDate(date) {
  console.log(typeof date)
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 101).toString().substring(1);
  var day = (date.getDate() + 100).toString().substring(1);
  return year + "-" + month + "-" + day;
}

function getDate (d) {
    return d ? new Date(d * 1000) : new Date()
}

decorate(CaseInfo, {
  age: computed,

  // Personal Details form obserables
  createdAt: observable,
  id: observable,
  caseStatus: observable,
  lastName: observable,
  givenNames: observable,
  gender: observable,
  dateOfBirth: observable,
  civilStatus: observable,
  placeOfOrigin: observable,
  otherCountry: observable,
  passportNo: observable,
  hkid: observable,
  idVCaNo: observable,
  contractNo: observable,
  firstArrivalDate: observable,
  contractStartDate: observable,
  contractEndDate: observable,
  employerName: observable,
  salaryInContract: observable,
  salaryReceived: observable,
  contractStatus: observable,
  contractTerminatedBy: observable,
  contractTerminationNotice: observable,
  wagesPaidInLieuOfNotice: observable,
  visaValidity: observable,
  localContact: observable,
  localContactNo: observable,
  referredBy: observable,
  referenceContactNo: observable,
  employerNationality: observable,
  householdMembers: observable,
  workAddress: observable,
  employerContactNo: observable,
  noOfContractsWithCurrentEmployer: observable,
  noOfHKEmployers: observable,
  agencyNameAtOrigin: observable,
  financeAgencyNameAtOrigin: observable,
  agencyAddressAtOrigin: observable,
  managerNameAtOrigin: observable,
  managerContactAtOrigin: observable,
  agencyNameInHK : observable,
  financeAgencyNameInHK : observable,
  agencyAddressInHK: observable,
  currency: observable,
  managerNameInHK : observable,
  managerContactInHK: observable,
  recruitedByAgency: observable,
  directHire: observable,
  recruitedBy: observable,
  agencyFeesInHK: observable,
  agencyFeesAtOrigin: observable,
  agencyFeesAtOriginCcy : observable,
  helperContactNo: observable,

  // set personal details fields as obvervable
  monetaryClaim: observable,
  workAndLivingCondition: observable,
  accommodation: observable,
  shelterReferral: observable,
  illegalFeesCollection: observable,
  overcharging: observable,
  fraudulentLoan: observable,
  confiscation: observable,
  physicalAssault: observable,
  sexualHarassment: observable,
  accused: observable,
  scam: observable,
  personalLoan: observable,
  immigration: observable,
  familyIssue: observable,
  consulate: observable,
  otherPersonalProblems: observable,
  //additional details 
  caseDescription: observable,
  chronology: observable,
  compensation_comment: observable,
  shelterName: observable,
  
  // Monetary Claims
  wagesInArrears : observable,
  wagesInLieuOfNotice: observable,
  restDays: observable,
  annualLeaveDays: observable,
  statutoryHolidays: observable,
  medicalFee: observable,
  officialExpenseReimbursements: observable,
  travelAllowance: observable,
  airTicket: observable,
  travelTaxInsurance: observable,
  travelLocalTransport: observable,
  travelAirportToDestination: observable,
  lessClaims: observable,
  others:observable,
  compensationComment: observable,
  
  //Case Update sheet
  settlementAmountReceived: observable,
  settlementAmountClaimed: observable,
  immigrationCaseDetails: observable,
  policeCaseDetails: observable,
  familyProblemDetails:observable,
  personalLoanIssue: observable,
  clientCurrentStatus: observable,
  caseClosingDate: observable,
  additionalComments: observable,
  //Attachments
  attachments : observable
});

const storeCaseInfo = new CaseInfo();

// for debugging
window.storeCaseInfo = storeCaseInfo;

export {
  storeCaseInfo,
};
