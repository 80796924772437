
import { decorate, observable, action } from 'mobx';
import http from './http';
import store from './globals'

class StoreUserList {
  list = [];
  id = null
  name = '';
  email = '';
  search_name = '';
  search_email = '';
  showLoadMore = false;

  clearSearchList() {
    this.list = []
  }
  filterValues = (user) => {
    return this.list.filter(useritem => {
      return useritem.id !== user;
    });
  }

  filterUserByName = (value) => {
    return this.list.filter(useritem => {
      return useritem.name.includes(value);
    });
  }

  filterUserByEmail = (value) => {
    return this.list.filter(useritem => {
      return useritem.email.includes(value);
    });
  }

  removeUser = (user) => {
    http.post('/api/user/remove', { 'id': user }).then(({ data }) => {
      if (data.code === 200) {
        this.list = this.filterValues(user)
      }
    });
  }

  resetUser = (user) => {
    http.post('/api/user/reset', { 'user_id': user }).then(({ data }) => {
      if (data.code === 200) {
        store.showModal = true
      }
    });
  }

  search = (value) => {
    if (this.search_name) {
      this.list = this.filterUserByName(this.search_name)
    }
    if (this.search_email) {
      this.list = this.filterUserByEmail(this.search_email)
    }
  }

  getList = (loadMore = false) => {
    const p = {};
    if (this.name) {
      p.name = this.name;
    }
    if (this.email) {
      p.email = this.email
    }
    if (loadMore && this.list.length) {
      p.cursor_id = this.list[this.list.length - 1].id;
    }
    http.get('/api/user/list', {
      params: p
    }).then(({ data }) => {
      if (data.code !== 200) {
        alert('Error : '+ data.msg);
        return;
      }
      this.showLoadMore = !!data.data.length;
      if (loadMore) {
        this.list = [...this.list, ...data.data];
      } else {
        // new 
        this.list = data.data;
      }
    });
  }
}

decorate(StoreUserList, {
  list: observable,
  id: observable,
  name: observable,
  email: observable,
  showLoadMore: observable,
  getList: action,
  searchUser: action
});

const storeUserList = new StoreUserList();

export default storeUserList;
