import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { observer } from 'mobx-react';

export const makeForm = (definition, store, spacing = 1, defaultProps = { xs: 12, md: 6, }) => {
  const makeComponent = (def, store, key) => {
    if (Array.isArray(def)) {
      return <Grid container spacing={spacing} key={key}>
        {def.map((one, index) => makeComponent(one, store, index))}
      </Grid>;
    }
    const Comp = components[def.type];
    if (!Comp) {
      throw new Error(`invalide component type '${def.type}'`);
    }
    const props = { ...defaultProps, ...(def.props || {}), name: def.name, label: def.label, store };
    return <Grid item xs={props.xs} md={props.md} key={key}>
      <Comp {...props} />
    </Grid>;
  }
  return makeComponent(definition, store, 0);
};

const withSetValue = (options = {}) => Comp => {
  return class tmp extends Component {
    handleChange = (evt) => {
      const value = evt.target.value;
      if (this.props.onBeforeSetValue && !this.props.onBeforeSetValue(value)) {
        return;
      }
      if (this.props.onSetValue) {
        this.props.onSetValue(value);
      } else {
        this.props.store[this.props.name] = value;
      }
      if (this.props.onAfterSetValue) {
        this.props.onAfterSetValue(value);
      }
    }
    render() {
      return <Comp {...this.props} onChange={this.handleChange} />;
    }
  }
};

const MText = observer(class MText extends Component {
  render() {
    return <TextField {...this.props}
      name={this.props.name} label={this.props.label} fullWidth
      value={(this.props.store[this.props.name] === null || this.props.store[this.props.name] === undefined) ? '' : this.props.store[this.props.name]}
      InputLabelProps={{ shrink: true }}
    />;
  }
});

const MRadioGroup = observer(class MRadioGroup extends Component {
  render() {
    return <FormControl component="fieldset">
      <FormLabel component="legend">{this.props.label}</FormLabel>
      <RadioGroup row {...this.props} name={this.props.name} value={this.props.store[this.props.name] || ''}>
        {this.props.items.map((values, index) => (
          <FormControlLabel
            key={index} value={values[1]} control={<Radio color="primary" />} label={values[0]}
          />))
        }
      </RadioGroup>
    </FormControl>;
  }
});


const MDropdownList = observer(class MDropdownList extends Component {
  render() {
    return <TextField {...this.props} select
      name={this.props.name} label={this.props.label} fullWidth
      value={this.props.store[this.props.name] || ''}
      InputLabelProps={{ shrink: true }}
      SelectProps={{ native: true, MenuProps: {} }}
    >
      {
        this.props.items.map((option, index) => <option key={index} value={option[1]}>{option[0]}</option>)
      }
    </ TextField>;
  }
});

const Seperator = (props) => <div />;

const components = {
  text: observer(withSetValue()(MText)),
  radioGroup: observer(withSetValue()(MRadioGroup)),
  dropdown: observer(withSetValue()(MDropdownList)),
  seperator: Seperator
}
