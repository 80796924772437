import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import storeCurrentUser from './current_user';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import PopupDialog from './popup_dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


const MyProfile = observer(class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    storeCurrentUser.getUser();
  }

  handleChange = () => {
    return (event) => {
      let name = event.target.name;
      storeCurrentUser[name] = event.target.value;
    };
  };
  render() {
    return <>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Typography component="h2" variant="h4" gutterBottom>My Profile</Typography>
        </Grid>
        <Grid item md={6}>
          <Paper>
            <Box m={1}>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TextField
                    name="first_name"
                    label="First Name"
                    onChange={this.handleChange()}
                    value={storeCurrentUser.first_name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="last_name"
                    label="Last Name"
                    onChange={this.handleChange()}
                    value={storeCurrentUser.last_name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="username"
                    label="Username"
                    onChange={this.handleChange()}
                    value={storeCurrentUser.username}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="email"
                    label="Email"
                    onChange={this.handleChange()}
                    value={storeCurrentUser.email}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="role_id"
                    label="User Role"
                    value={storeCurrentUser.role}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Button
                    onClick={() => { storeCurrentUser.updateUser() }}
                    variant="contained"
                    color="primary"
                  >Update My Profile</Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper>
            <Box m={1}>
              <Grid container spacing={1}>
                <Grid item md={7}>
                  <TextField
                    name="old_password"
                    label="Old Password"
                    type="password"
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={7}>
                  <TextField
                    name="new_password"
                    label="New Password"
                    type="password"
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={7}>
                  <TextField
                    name="new_password_2"
                    label="Confirm New Password"
                    type="password"
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={7}>
                  <Button
                    onClick={() => { storeCurrentUser.updatePassword() }}
                    variant="contained"
                    color="primary"
                  >Update My Password</Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <PopupDialog title="MFMW" body="Your profile been updated" />
    </>;
  }
});

export default MyProfile;