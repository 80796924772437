import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import storeUser from './store_user';
import { observer } from 'mobx-react';
import PopupDialog from './popup_dialog';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from './styles';

const UserCreationPanel = observer(class UserCreationPanel extends React.Component {
  state = {'role_id':'2'}
  handleChange = () => {
    return (event) => {
      let name = event.target.name
      console.log(name + '=' + event.target.value);
      storeUser[name] = event.target.value
    };
  };

  handleRoleChange = () => {
    return (event) => {
      console.log(event.target.value);
      storeUser['role_id'] = event.target.value
      console.log(storeUser.role_id)
      this.setState({'role_id':storeUser.role_id})
    };
  };
  render() {
    return (
      <>
        <Typography variant="h4" gutterBottom>Create New User</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="first_name"
              label="First Name"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="last_name"
              label="Last Name"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="username"
              label="Username"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="email"
              label="Email"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="password2"
              label="Confirm Password"
              type="password"
              onChange={this.handleChange()}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid items xs={12} md={4}>
            <FormControl>
              <RadioGroup name='role_id' id='role_id'  row value={this.state.role_id} onChange={this.handleRoleChange()}>
                <FormControlLabel value='1' control={<Radio color="primary" />} label={"Admin"} />
                <FormControlLabel value='2' control={<Radio color="primary" />} label={"Case Officer"} />
                <FormControlLabel value='3' control={<Radio color="primary" />} label={"Intern"} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid items xs={12} md={4}>
            <Button
              onClick={() => { storeUser.addUser() }}
              variant="contained"
              color="primary"
            >Add User</Button>
            <PopupDialog title="MFMW" body="User has been created" />
          </Grid>
        </Grid>
      </>
    );
  }
});

export default withStyles(styles)(UserCreationPanel);