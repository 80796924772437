import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { AddBoxRounded, CancelRounded, DeleteRounded, Info, UpdateRounded } from "@material-ui/icons";
import React, { Component } from 'react';
import http from './http';
import { storeCaseInfo } from './store_case_info';
import storeCaseList from './store_case_list';
import StyledTableCell from './styled_table_cell';
import StyledTableRow from './styled_table_row';

var asOfDate = new Date()
asOfDate = asOfDate.getDate() + '/' + asOfDate.getMonth() + '/' + asOfDate.getFullYear()

class Chronology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectId: this.props.object,
      items: [],
      selectedId: null,
      isAddingNew: false,
      textToBeAdded: '',
    };
  }

  loadData = () => {
    http.get('/api/case/chronology/list', { params: { object_id: this.state.objectId } }).then(({ data }) => {
      this.setState({
        items: data.data,
      })
    })
  }

  componentDidMount() {
    this.loadData();
  }

  getDate = (d) => {
    return d ? new Date(d * 1000).toLocaleDateString("en-GB") : ''
  };

  handleChange = (name) => {
    return (event) => {
      storeCaseList[name] = event.target.value;
    };
  }

  selectRow = (id, text) => __ => {
    this.setState({
      isAddingNew: false,
      selectedId: id,
      textToBeAdded: text,
    });
  }

  deselectRow = (id) => __ => {
    this.setState({
      isAddingNew: false,
      selectedId: -1,
      textToBeAdded: '',
    });
  }

  addItem = () => {
    this.setState({
      isAddingNew: true,
      textToBeAdded: '',
    });
  }

  deleteItem = () => {
    const payload = {
      remark: this.state.textToBeAdded,
      id: this.state.selectedId
    };
    http.post('/api/case/chronology/remove', payload).then(({ data }) => {
      this.loadData();
      this.setState({
        isAddingNew: false,
        selectedId: -1,
        textToBeAdded: '',
      });
    });
  }

  cancelAddItem = () => {
    this.setState({
      isAddingNew: false,
      textToBeAdded: '',
    });
  }

  textOnChange = e => {
    this.setState({
      textToBeAdded: e.target.value,
    });
  }

  submit = () => {
    const payload = {
      remark: this.state.textToBeAdded,
    };
    if (this.state.isAddingNew) {
      payload.case_id = this.state.objectId;
    } else {
      payload.id = this.state.selectedId;
    }
    http.post(this.state.isAddingNew ? '/api/case/chronology/add' : '/api/case/chronology/update', payload).then(({ data }) => {
      this.setState({
        isAddingNew: false,
        selectedId: -1,
        textToBeAdded: '',
      },
        this.loadData);
    });
  }

  render() {
    return <div id='chronology'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Case ID #"
                    value={storeCaseInfo.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Client"
                    value={storeCaseInfo.givenNames + " " + storeCaseInfo.lastName}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Monetary Claims As Of"
                    value={asOfDate}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
      <Table >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Created By</StyledTableCell>
            <StyledTableCell>Created At</StyledTableCell>
            <StyledTableCell>Text</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {this.state.items.map((row, i) => (
            row.editable && this.state.selectedId === row.id ?
              <StyledTableRow key={`row-${row.id}`}>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  {new Date().toLocaleDateString("en-GB")}
                </StyledTableCell>
                <StyledTableCell>
                  <TextField fullWidth multiline onChange={this.textOnChange} defaultValue={this.state.textToBeAdded} />
                </StyledTableCell>
                <StyledTableCell>
                  <br></br>
                  <Tooltip title="Update">
                    <IconButton onClick={this.submit} variant="contained" color="primary">
                      <UpdateRounded />
                    </IconButton>
                  </Tooltip>
                  <br></br>
                  <Tooltip title="Delete">
                    <IconButton onClick={this.deleteItem} variant="contained" color="secondary">
                      <DeleteRounded />
                    </IconButton>
                  </Tooltip>
                  <br></br>
                  <Tooltip title="Cancel">
                    <IconButton onClick={this.deselectRow(row.id)} variant="contained" color="secondary">
                      < CancelRounded />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
              :
              <StyledTableRow key={`row-${row.id}`}>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{this.getDate(row.created_at)}</StyledTableCell>
                <StyledTableCell>
                  <TextField fullWidth multiline defaultValue={row.remark} InputProps={{ readOnly: true, }} />
                </StyledTableCell>
                <StyledTableCell>
                  {row.editable && <Button color="primary" onClick={this.selectRow(row.id, row.remark)}>Edit</Button>}
                </StyledTableCell>
              </StyledTableRow>
          )
          )}
        </TableBody>
        {this.state.isAddingNew ?
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
                {new Date().toLocaleDateString("en-GB")}
              </StyledTableCell>
              <StyledTableCell>
                <TextField fullWidth multiline onChange={this.textOnChange} defaultValue={this.state.textToBeAdded} />
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip title="Add">
                  <IconButton onClick={this.submit} variant="contained" color="primary">
                    <AddBoxRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton onClick={this.cancelAddItem} variant="contained" color="secondary">
                    <CancelRounded />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
          :
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell>
                <Button onClick={this.addItem} variant="contained" color="primary">Add</Button>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        }
      </Table>
    </div>;
  }
}

export default Chronology;