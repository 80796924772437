import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import http from './http';
import { HorizontalBar } from 'react-chartjs-2';
import styles from './styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const chartOptions = {
  elements: {
    rectangle: {
      borderWidth: 2,
    }
  },
  responsive: true,
  legend: {
    position: 'right',
  },
  title: {
    display: false,
  }
};

//Hope easy way to format date in Java script - 
function formatDate(date) {
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 101).toString().substring(1);
  var day = (date.getDate() + 100).toString().substring(1);
  return year + "-" + month + "-" + day;
}

const fromDateStr = formatDate(new Date(new Date().getFullYear(), 0, 1));
const toDateStr = formatDate(new Date());

const statTypes = [
  {
    value: 'case',
    label: 'Problem Types'
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'country',
    label: 'Country',
  },
  {
    value: 'year',
    label: 'Age',
  },
  {
    value: 'civil_status',
    label: 'Civil Status',
  },
  {
    value: 'shelter',
    label: 'Shelter Name',
  },
  {
    value: 'finance_agency_hk',
    label: 'Finance Agency in Hong Kong',
  },
  {
    value: 'finance_agency_origin',
    label: 'Finance Agency at Origin',
  },
  {
    value: 'closed_cases',
    label: 'Case Status',
  },
  {
    value: 'agency_at_hk',
    label: 'Agency Name in Hong Kong',
  },
  {
    value: 'agency_at_origin',
    label: 'Agency Name at Origin',
  },
];

class StatCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      statType: 'case',
      fromDate: fromDateStr,
      toDate: toDateStr,
    };
  }

  unix_timestamp = (dateStr) => {
    return Math.floor(new Date(dateStr).getTime() / 1000);
  }

  handleChange = (name) => {
    return (event) => {
      this.setState({
        [name]: event.target.value,
      }, this.getChartData);
    };
  };

  makeData(data) {
    const labels = Object.keys(data)
    const index = labels.indexOf('');
    if (~index) {
      labels[index] = 'N/A';
    }

    const res = {
      labels: labels,
      datasets: [{
        backgroundColor: 'rgba(99,132,255,0.2)',
        label: 'Count',
        data: Object.values(data) //labels.map(one => data[one]),
      }]
    }
    return res;
  }

  getChartData() {
    const endPoint = '/api/stat/' + this.state.statType
    http.get(endPoint, {
      params: {
        'from_timestamp': this.unix_timestamp(this.state.fromDate),
        'to_timestamp': this.unix_timestamp(this.state.toDate)
      }
    }).then(({ data }) => {
      if (data.code === 200) {
        this.setState({
          data: this.makeData(data.data),
        });
      } else {
        this.setState({ data: {} });
      }
    })
  }

  componentDidMount() {
    this.getChartData()
  }

  render() {
    const { classes } = this.props;
    return <main className={classes.layout}>
      <Typography component="h2" variant="h4" gutterBottom>Statistics</Typography>
      <Divider />
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <TextField
              id="chartTypeField"
              select
              fullWidth
              label="Chart Type"
              className={classes.textField}
              value={this.state.statType}
              // defaultValue={this.state.statType}
              onChange={this.handleChange('statType')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
            >
              {statTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="fromDateField"
              label="From Date"
              type="date"
              className={classes.textField}
              defaultValue={this.state.fromDate}
              onChange={this.handleChange('fromDate')}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="toDateField"
              label="To Date"
              type="date"
              className={classes.textField}
              defaultValue={this.state.toDate}
              onChange={this.handleChange('toDate')}
            />
          </Grid>
          {/* <Grid item xs={6} md={2}>
            <Button
              onClick={() => {
                this.getChartData()
              }}
              variant="contained"
              color="primary"
            >Show Chart</Button>
          </Grid> */}
        </Grid>
      </Paper>
      <Paper >
        {this.state.data && <HorizontalBar data={this.state.data} options={chartOptions} />}
      </Paper>
    </main >;
  }
}

export default withStyles(styles)(StatCase);