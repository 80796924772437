import { decorate, observable } from 'mobx';
import http from './http';
import store from './globals';

class StoreUser {
  username = '';
  email = '';
  first_name = '';
  last_name = '';
  password = '';
  password2 = '';
  role_id = 2;

  addUser = () => {
    var md5 = require('md5');
    let pass = md5(this.password);
    let pass2 = md5(this.password2);
    if (this.username === '' || this.email === '') {
      window.alert('Username/Email can not be empty');
    } else {
      http.post('/api/user/add', {
        'email': this.email,
        'username': this.username,
        'first_name': this.first_name,
        'last_name': this.last_name,
        'password': pass,
        'password2': pass2,
        'role_id': this.role_id
      }).then(({ data }) => {
        if (data.code === 200) {
          store.showModal = true;
        } else if (data.code === 400) {
          window.alert('Please fill in all the fields')
        } else if (data.code === 409) {
          window.alert('User already exist')
        } else if (pass !== pass2) {
          window.alert('Password is not matching Confirm Password')
        }
      });
    }
  }
}

decorate(StoreUser, {
  username: observable,
  email: observable,
  first_name: observable,
  last_name: observable,
  password: observable,
  password2: observable,
  role_id: observable
});

const storeUser = new StoreUser();

export default storeUser;

window.storeUser = storeUser
