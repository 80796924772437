import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { storeCaseInfo } from './store_case_info';
import TextField from '@material-ui/core/TextField';
import { storeStaticList } from './store_static';

const makePair = (...a) => a.map(item => ([item, item]))

const ProblemDetails = observer(class ProblemDetails extends React.Component {

  handleChange = (name) => {
    return (event) => {
      storeCaseInfo[name] = event.target.value;
    };
  };

  handleCheckBoxChange = (name) => {
    return (event) => {
      storeCaseInfo[name] = event.target.checked ? 1: 0 ;
      console.log(storeCaseInfo[name]);

    };
  };

  render() {
    const shelterList = makePair(...storeStaticList.static_map['ShelterName']);

    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Labour Ralated</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={storeCaseInfo.monetaryClaim}
                      onChange={this.handleCheckBoxChange('monetaryClaim')}
                      value="monetaryClaim"
                    />
                  }
                  label="Monetary Claim"
                />
                <FormControlLabel control={<Switch checked={storeCaseInfo.workAndLivingCondition} onChange={this.handleCheckBoxChange('workAndLivingCondition')} />} label="Work and Living Condition" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.accommodation} onChange={this.handleCheckBoxChange('accommodation')} />} label="Accomodation" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.shelterReferral} onChange={this.handleCheckBoxChange('shelterReferral')} />} label="Shelter Referral" />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Agency Ralated</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Switch checked={storeCaseInfo.illegalFeesCollection} onChange={this.handleCheckBoxChange('illegalFeesCollection')} value="illegalFeesCollection" />} label="Illegal fees Collection" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.overcharging} onChange={this.handleCheckBoxChange('overcharging')} value="overcharging" />} label="Overcharging" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.fraudulentLoan} onChange={this.handleCheckBoxChange('fraudulentLoan')} value="fraudulentLoan" />} label="Fraudulent Loan" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.confiscation} onChange={this.handleCheckBoxChange('confiscation')} value="confiscation" />} label="Confiscation" />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Police Ralated</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Switch checked={storeCaseInfo.physicalAssault} onChange={this.handleCheckBoxChange('physicalAssault')} value="physicalAssault" />} label="Physical Assault" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.sexualHarassment} onChange={this.handleCheckBoxChange('sexualHarassment')} value="sexualHarassment" />} label="Sexual Harassement" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.accused} onChange={this.handleCheckBoxChange('accused')} value="accused" />} label="Accused of Theft/Assualt/Abuse/Neglect" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.scam} onChange={this.handleCheckBoxChange('scam')} value="scam" />} label="Scam" />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Others</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Switch checked={storeCaseInfo.personalLoan} onChange={this.handleCheckBoxChange('personalLoan')} value="personalLoan" />} label="Personal Loan" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.immigration} onChange={this.handleCheckBoxChange('immigration')} value="immigration" />} label="Immigration Related" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.familyIssue} onChange={this.handleCheckBoxChange('familyIssue')} value="familyIssue" />} label="Family Related" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.consulate} onChange={this.handleCheckBoxChange('consulate')} value="consulate" />} label="Consulate Related" />
                <FormControlLabel control={<Switch checked={storeCaseInfo.otherPersonalProblems} onChange={this.handleCheckBoxChange('otherPersonalProblems')} value="otherPersonalProblems" />} label="Others" />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              name="caseDescription"
              label="Brief Case Description"
              fullWidth
              multiline
              rows='5'
              value={storeCaseInfo.caseDescription}
              onChange={this.handleChange('caseDescription')}
              InputLabelProps={{ shrink: true }}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="shelterName"
              label="Shelter Name"
              fullWidth
              select
              value={storeCaseInfo.shelterName}
              onChange={this.handleChange('shelterName')}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true, MenuProps: {} }}
              //variant='outlined'
              >
              {
                shelterList.map((option, index) => <option key={index} value={option[1]}>{option[0]}</option>)
              }
            </TextField>
          </Grid>
        </Grid>
      </div>
    );
  }
});

export default ProblemDetails;