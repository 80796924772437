import React, { Component } from 'react';
import PersonalDetailsForm from './personal_details_form';
import Attachments from './attachments'
import ProblemDetails from './problem_details'
import MonetaryClaimsForm from './monetary_claims'
import EmploymentDetailsForm from './employment_details'
import CaseSettlementForm from './case_settlement'
import Chronology from './chronology'
import store from './globals';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles';
import { storeCaseInfo } from './store_case_info';
import { withRouter } from 'react-router';
import axios from './http';
import PopupDialog from './popup_dialog';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AdvancedSetting from './inquery_advanced';
import { observer } from 'mobx-react';

const ChronologyTab = observer((props) => <div>
  {
    storeCaseInfo.id !== null
      ? <Chronology object={storeCaseInfo.id} />
      : <span>You need to save this case first then add new items.</span>
  }
</div>);


const divisions = {
  personal: 'personal_details',
  problem: 'problem_details',
  compensation: 'monetary_claims',
  employmentDetails: 'employment_details',
  caseSettlement: 'case_updates',
  chronology: 'chronology',
}

const pages = [
  { title: 'personal', component: <PersonalDetailsForm /> },
  { title: 'problem', component: <ProblemDetails /> },
  { title: 'compensation', component: <MonetaryClaimsForm /> },
  { title: 'employmentDetails', component: <EmploymentDetailsForm /> },
  { title: 'chronology', component: <ChronologyTab /> },
  { title: 'caseSettlement', component: <CaseSettlementForm /> },
  { title: 'attachments', component: <Attachments /> },
  { title: 'advanced', component: <AdvancedSetting /> },
];

class InquiryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const prev = (prevProps.match && prevProps.match.params) || {};
      const current = (this.props.match && this.props.match.params) || {};
      if (prev.id !== current.id) {
        if (!current.id) {
          storeCaseInfo.clearStoreDetails();
        } else {
          axios.get('/api/case/detail?id=' + this.props.match.params.id).then(r => {
            if (r.status === 200) {
              if (r.data.code === 1) {
                window.alert(' Error ' + r.data.msg)
                return
              }
              storeCaseInfo.setDetailsFromServer(r.data.data)
            }
          });
        }
      }
    }
  }

  componentWillUnmount() {
    storeCaseInfo.clearStoreDetails();
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      axios.get('/api/case/detail?id=' + this.props.match.params.id).then(r => {
        if (r.status === 200) {
          if (r.data.code === 1) {
            window.alert(' Error ' + r.data.msg)
            return
          }
          storeCaseInfo.setDetailsFromServer(r.data.data)
        }
      });
    }
  }

  handleSave = () => {
    let missingFields = storeCaseInfo.personalDetailsCheck()
    console.log('Length of missing fields ' + missingFields.length)
    if (missingFields.length === 0) {
      storeCaseInfo.saveDetail();
      store.showModal = true;
    } else {
      window.alert('Missing fields ' + missingFields.join())
    }
  }

  handleClear = () => {
    storeCaseInfo.clearStoreDetails();
  }

  handleClose = () => {
    this.setState({ openSaveConfirmationDialog: false });
  };

  handlePrint = (e) => {
    var css = '@page { size: A3 landscape; }',
      style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    //looks better with transparent logo - mfmw_logo.png, but somehow that doesn't get loaded
    var logo = document.createElement("img");
    logo.src = window.location.origin + '/mfmw_logo_1.png'
    logo.style.width = "5%";
    logo.style.height = "5%";
    logo.style.float = "left";
    logo.style.marginRight = "1%";


    var logo_pane = document.createElement("div");
    logo_pane.innerHTML = logo.outerHTML + '<h2> Serving Migrants, Anytime, Anywhere </h2>';

    const selected_div = divisions[store.selected]
    var printContents = document.getElementById(selected_div).innerHTML;
    document.body.innerHTML = logo_pane.outerHTML + printContents;
    document.body.appendChild(style);
    window.print();
    window.location.reload()
  }

  handleTabChange = (__, value) => {
    if (value === store.selected) {
      return;
    }
    let next_url = '/inquiry/' + value;
    if (storeCaseInfo.id) {
      next_url = next_url + '/' + storeCaseInfo.id;
    }
    this.props.history.push(next_url);
  }

  render() {
    store.selected = this.props.match.params.selected;
    const { classes } = this.props;
    let component = null;
    for (let one of pages) {
      if (store.selected === one.title) {
        component = one.component;
      }
    }

    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"

            indicatorColor="primary"
            textColor="primary"
            value={store.selected}
            aria-label="simple tabs example">
            {pages.map((one, index) => <Tab
              key={index}
              label={one.title}
              value={one.title}
            />)}
          </Tabs>
        </AppBar>
        {component}
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleSave}>Save</Button>
          {/* <Button
            variant="contained"
            className={classes.button}
            onClick={this.handleClear}>Clear</Button> */}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!['compensation', 'chronology'].includes(this.props.match.params.selected)}
            onClick={this.handlePrint}>Print</Button>
        </div>
        <PopupDialog title="MFMW" body="Inquiry has been saved" />
      </>
    );

  }
};

InquiryForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(InquiryForm));