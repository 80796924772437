import React from 'react';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import FileUpload from './file_upload';
import axios from './http';
import { storeCaseInfo } from './store_case_info';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import store from './globals';


const Attachments = observer(class AdditionalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.base_url = axios.defaults.baseURL + "/api/file/"

  }
  componentDidMount() {
    if (storeCaseInfo.id) {
      axios.get('/api/attachment/list?case_id=' + storeCaseInfo.id).then(r => {
        if (r.status === 200) {
          if (r.data.code === 1) {
            window.alert(' Error ' + r.data.msg)
            return
          }
          storeCaseInfo.setAttachmentsFromServer(r.data.data)
        }
      });
    }
  }


  handleDelete = (indexToDelete) => {
    var toBeDeleted = storeCaseInfo.attachments[indexToDelete]
    if (toBeDeleted.id) {
      axios.post('/api/attachment/remove', { 'id': toBeDeleted.id, 'session': store.currentSession }).then(r => {
        if (r.status === 200) {
          if (r.data.code === 1) {
            window.alert(' Error ' + r.data.msg)
            return
          }
          storeCaseInfo.attachments = storeCaseInfo.attachments.filter((_, index) => index !== indexToDelete);
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Typography component="h2" variant="h6" gutterBottom>
          Browse / Drop Files to be attached :
        <FileUpload></FileUpload>
        </Typography>
        <Typography component="h2" variant="h6" gutterBottom>
          Current Attachments are :
          <List>
            {storeCaseInfo.attachments.map((value, index) => {
              return (
                <ListItem key={value.path} dense button component="a" href={this.base_url + value.path} target="_blank">
                  <ListItemIcon />
                  <AttachmentIcon />
                  <ListItemText primary={value.remark || value.path} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end"
                      onClick={() => {
                        this.handleDelete(index);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Typography>
      </React.Fragment>
    );
  }
});

export default withStyles(styles)(Attachments);