import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from '@material-ui/core/List';
import { AccountBox, ShowChart, SupervisorAccount } from "@material-ui/icons";
import { Face, EditAttributes } from "@material-ui/icons";
import { withRouter } from 'react-router-dom'

class ListItemComposition extends React.Component {
 
  componentWillUnmount()
  {
    this.props.history.location.pathname ='/query'

  }
  state = {
    openInquiry: true,
    openAdmin: true
  }

  handleOpenInquiryClick = () => {
    this.setState(state => ({ openInquiry: !state.openInquiry }));
  };

  handleOpenAdminClick = () => {
    this.setState(state => ({ openAdmin: !state.openAdmin }));
  };

  go = (url) => () => {
    this.props.history.push(url);
    console.log(this.props.history)
  }
  
  render() {
    return (
      <div>
        <ListItem button onClick={this.handleOpenAdminClick} >
          <ListItemIcon>
            <SupervisorAccount />
          </ListItemIcon>
          <ListItemText disableTypography primary="Admin" />
          {this.state.openAdmin ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openAdmin} timeout="auto" unmountOnExit>
          <List component="div" style={{ marginLeft: '2em' }}>
            <ListItem button onClick={this.go('/admin/user_create')}>
              <ListItemIcon><Face /></ListItemIcon>
              <ListItemText primary="Create User" />
            </ListItem>
            <ListItem button onClick={this.go('/admin/user')}>
              <ListItemIcon><EditAttributes /></ListItemIcon>
              <ListItemText primary="Maintain User" />
            </ListItem>
            <ListItem button onClick={this.go('/admin/static')}>
              <ListItemIcon><EditAttributes /></ListItemIcon>
              <ListItemText primary="Maintain Static" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={() => { this.handleOpenInquiryClick(); this.go('/inquiry/personal')(); }}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="New Inquiry" />
        </ListItem>

        <ListItem button onClick={this.go('/query')}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem>
        <ListItem button onClick={this.go('/stat/case')}>
          <ListItemIcon>
            <ShowChart />
          </ListItemIcon>
          <ListItemText primary="Statistics" />
        </ListItem>
        <ListItem button onClick={this.go('/my_profile')}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItem>
      </div>
    );
  }
}

export default withRouter(ListItemComposition);