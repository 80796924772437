
import { decorate, observable, action } from 'mobx';
import http from './http';
import store from './globals'

class StoreStaticList {
  list = [];
  key = '';
  value = '';
  newkey = '';
  newvalue = '';
  data = [];
  static_map = {
    'Country': [], 'Currency': [],
    'AgencyNameAtOrigin': [], 'FinanceAgencyNameAtOrigin': [],
    'AgencyNameAtHK': [], 'FinanceAgencyNameAtHK': []
  };
  keys = [];

  clearSearchList() {
    this.list = [];
    this.keys = [];
    this.static_map = {
      'Country': [], 'Currency': [],
      'AgencyNameAtOrigin': [], 'FinanceAgencyNameAtOrigin': [],
      'AgencyNameAtHK': [], 'FinanceAgencyNameAtHK': []
    };
  }
  filterValues = (key, value) => {
    return this.list.filter(static_item => {
      return static_item.key !== key || static_item.value !== value;
    });
  }

  filterByKey = (value) => {
    return this.list.filter(static_item => {
      return static_item.key.includes(value);
    });
  }

  filterByValue = (value) => {
    return this.list.filter(static_item => {
      return static_item.value.includes(value);
    });
  }

  removeStatic = (key, value) => {
    http.post('/api/static/remove', { 'key': key, 'value': value }).then(({ data }) => {
      if (data.code === 200) {
        this.list = this.filterValues(key, value)
      }
    });
  }

  addStatic = () => {
    if (this.newkey === 'Static Type' || this.newkey === '') {
      window.alert('Please select the static type!')
    } else {
      http.post('/api/static/add', { 'key': this.newkey, 'value': this.newvalue }).then(({ data }) => {

        if (data.code === 200) {
          store.showModal = true
        } else {
          console.log(data)
        }
      });
    }
  }

  search = (key, value) => {
    if (this.key) {
      this.list = this.filterByKey(this.key)
    }
    if (this.value) {
      this.list = this.filterByValue(this.value)
    }
  }

  getList = async (value) => {
    await http.get('/api/static/list', {
    }).then(({ data }) => {
      if (data.code === 200) {
        this.list = data.data;
        var i;
        for (i = 0; i < this.list.length; i++) {
          this.keys.push(this.list[i].key)
        }
        this.keys = [['NA'], ...new Set(this.keys)]
        const getValue = (...a) => a.map(item => item.value)
        for (i = 0; i < this.keys.length; i++) {
          this.static_map[this.keys[i]] = [...['NA'], ...getValue(...this.filterByKey(this.keys[i]))]
        }
        if (this.key) {
          this.list = this.filterByKey(this.key)
        }
        if (this.value) {
          this.list = this.filterByValue(this.value)
        }
      }
      else {
        window.alert(data.msg)
      }
    })
  }
}

decorate(StoreStaticList, {
  list: observable,
  key: observable,
  value: observable,
  newkey: observable,
  newvalue: observable,
  keys: observable,
  static_map: observable,
  getList: action,
});

const storeStaticList = new StoreStaticList();
storeStaticList.getList();

// for debugging
window.storeStaticList = storeStaticList;

export { storeStaticList };