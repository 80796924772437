import React, { Component } from 'react';
import { observer } from 'mobx-react';

import SignIn from './signin_form';
import Dashboard from './dashboard';
import store from './globals';

const MFMWMain = observer(class MFMWMain extends Component {
  componentDidMount() {
    store.checkIsLoggedIn();
  }
  render() {
    return (
      store.isLoggedIn === undefined ? <div>Loading...</div> : (store.isLoggedIn ? <Dashboard /> : <SignIn />)
    );
  }
});

export default MFMWMain
