import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './service_worker';
import { BrowserRouter } from 'react-router-dom'
import MFMWMain from './main'
import theme from './theme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter><MFMWMain/></BrowserRouter>
    </ThemeProvider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
