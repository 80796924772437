import React, { Component } from "react";
import Dropzone from "./dropzone";
import "./file_upload.css";
import Progress from "./progress";
import store from './globals';
import { storeCaseInfo } from './store_case_info';
import axios from './http';
import withStyles from '@material-ui/core/styles/withStyles';

const error_prefix = 'Please Fix the errors below before clicking on "Upload" Button again :\n'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    rounded: 'true'
  },
  table: {
    minWidth: 700,
  },
});

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    var not_allowed = ''
    var allowed_extensions = ['txt', 'pdf', 'png', 'jpg', 'jpeg', 'gif', 'docx', 'doc', 'xlsx', 'xls']
    for (var file of this.state.files) {
      if (!allowed_extensions.includes(file.name.toLowerCase().split(".").pop(-1))) {
        not_allowed += 'Extension for file - ' + file.name + ' is not supported!\n'
      }
    }
    if (not_allowed) {
      window.alert(error_prefix + not_allowed)
    }
    else {
      if (storeCaseInfo.id === null) {
        window.alert(error_prefix + 'Cannot Upload attachments before saving the case.\nPlease Click on the Save Button First!');
      }
      else {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];
        this.state.files.forEach(file => {
          promises.push(this.sendRequest(file));
        });
        try {
          await Promise.all(promises);
          // console.log('Promise success = '+ promises)
          this.setState({ successfullUploaded: true, uploading: false });
        } catch (e) {
          // console.log('Promise failed = '+ e)
          // Not Production ready! Do some error handling here instead...
          this.setState({ successfullUploaded: false, uploading: false });
        }
      }
    }
    //Clearing the Files after Upload is done
    this.setState({ files: []}) 
  }



  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.onload = function () {
        var response = JSON.parse(req.responseText)
        console.log('File Upload Server Response : ' + req.responseText)
        if (response.code === 200) {
          console.log('SUCCESSFUL Upload for File = ' + file.name);
          var data = {
            case_id: storeCaseInfo.id,
            remark: file.name,
            id: response.data.id,
            path: response.data.path
          }
          storeCaseInfo.attachments.splice(0, 0, data)
        }
        else {
          console.log('FAILED Upload for File = ' + file.name);
          window.alert(error_prefix + 'Failed for ' + file.name + '\n Error = ' + response.msg)
        }
      };


      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        reject(req.response);
      });
      const formData = new FormData();
      formData.append("image", file);
      formData.append('session', store.currentSession || window.localStorage.getItem('session1'));
      formData.append('case_id', storeCaseInfo.id);
      formData.append('remark', file.name);

      console.log('Current Configs for File Upload');
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      req.open("POST", axios.defaults.baseURL + "/api/upload");
      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
        </div>
      );
    }
  }

  renderActions() {
    return (
      <div>
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
          style={{ margin: "5px" }}> Upload </button>

        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
          style={{ margin: "5px" }}>
          Clear
      </button>
      </div>
    );
  }

  render() {
    return (
      <div className="Upload">
        <span className="Title"></span>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">
                    {file.name}
                  </span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default withStyles(styles)(FileUpload);