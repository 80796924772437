import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import storeCaseList from './store_case_list';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';
import StyledTableRow from './styled_table_row';
import StyledTableCell from './styled_table_cell'
const SearchCase = observer(class SearchCase extends React.Component {
  componentDidMount() {
    console.log('Loading initial case data from the server');
    storeCaseList.getList()
  }

  getDate = (d) => {
    return d ? new Date(d * 1000).toLocaleDateString("en-US") : ''
  };

  handleChange = (name) => {
    return (event) => {
      storeCaseList[name] = event.target.value;
    };
  };

  render() {

    return (
      <main>
        <Typography component="h2" variant="h4" gutterBottom>
          Search Case
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              name="id"
              label="Case Number"
              fullWidth
              value={storeCaseList.id || ''}
              onChange={this.handleChange('id')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              name="given_names"
              label="Given Names"
              value={storeCaseList.given_names}
              fullWidth
              onChange={this.handleChange('given_names')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              name="hkid"
              label="HK ID"
              value={storeCaseList.hkid}
              fullWidth
              onChange={this.handleChange('hkid')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              name="passport_no"
              label="Passport No"
              value={storeCaseList.passport_no}
              fullWidth
              onChange={this.handleChange('passport_no')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={8} md={2}>
            <TextField
              name="case_status"
              label="Case Status"
              value={storeCaseList.case_status}
              fullWidth
              onChange={this.handleChange('case_status')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              onClick={() => {
                storeCaseList.clearSearchList();
                storeCaseList.getList()
              }}
              variant="contained"
              color="primary"
            >Search</Button>
          </Grid>
        </Grid>

        <Paper>
          {/* {storeCaseList.list.length ? */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Table>
                <colgroup>
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '85%' }} />
                  <col style={{ width: '5%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Case#</StyledTableCell>
                    <StyledTableCell>Given Names</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Nationality</StyledTableCell>
                    <StyledTableCell>Gender</StyledTableCell>
                    <StyledTableCell>Passport No</StyledTableCell>
                    <StyledTableCell>HK ID</StyledTableCell>
                    <StyledTableCell>Created By</StyledTableCell>
                    <StyledTableCell>Date of Entry</StyledTableCell>
                    <StyledTableCell>Updated By</StyledTableCell>
                    <StyledTableCell>Last Updated</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeCaseList.list.map((row, i) => {
                    return (
                      <StyledTableRow key={`row-${i}`}>
                        <StyledTableCell>
                          <Link to={`/inquiry/personal/${row.id}`} >
                            <Button>{`# ${row.id}`}</Button>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>{row.given_names}</StyledTableCell>
                        <StyledTableCell>{row.last_name}</StyledTableCell>
                        <StyledTableCell>{row.place_of_origin}</StyledTableCell>
                        <StyledTableCell>{row.gender}</StyledTableCell>
                        <StyledTableCell>{row.passport_no}</StyledTableCell>
                        <StyledTableCell>{row.hkid}</StyledTableCell>
                        <StyledTableCell>{row.created_by}</StyledTableCell>
                        <StyledTableCell>{this.getDate(row.created_at)}</StyledTableCell>
                        <StyledTableCell>{row.updated_by}</StyledTableCell>
                        <StyledTableCell>{this.getDate(row.updated_at)}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
        <div> </div>
        {
          storeCaseList.showLoadMore ?
            <Button
              onClick={() => { storeCaseList.getList(true) }}
              variant="contained"
              color="primary"
            >
              Load more...
            </Button> : null
        }
      </main >
    );
  }
});

export default SearchCase;