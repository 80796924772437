import React from 'react';
import { observer } from 'mobx-react';
import { storeCaseInfo } from './store_case_info';
import { makeForm } from './helper';

const CaseSettlementForm = observer(class CaseSettlementForm extends React.Component {

  render() {
    return (
      <React.Fragment>
        {makeForm([
          [
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Amount Claimed', name: "settlementAmountClaimed", props: { xs: 4, md: 2, type: 'number' } },
              { type: 'text', label: 'Amount Received', name: "settlementAmountReceived", props: { xs: 4, md: 2, type: 'number'} },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Immigration Case', name: "immigrationCaseDetails", props: { xs: 12, md: 6 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Police Case', name: "policeCaseDetails", props: { xs: 12, md: 6 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Family Problems', name: "familyProblemDetails", props: { xs: 12, md: 6 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Personal Loan Issue', name: "personalLoanIssue", props: { xs: 12, md: 6 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Client Current Status', name: "clientCurrentStatus", props: { xs: 12, md: 6 } },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Others', name: "additionalComments", props: { xs: 12, md: 6} },
            ],
            { type: 'seperator', props: { xs: 12, md: 4 } },
            [
              { type: 'text', label: 'Case Closing Date', name: "caseClosingDate", props: { xs: 3, md: 2, type: 'date'} },
            ],
          ]
        ], storeCaseInfo)}
      </React.Fragment >
    );
  }
});

export default CaseSettlementForm;