import { decorate, observable } from 'mobx';
import http from './http';
import store from './globals';
import { ContactSupportOutlined } from '@material-ui/icons';

class CurrentUser {
  username = '';
  email = '';
  first_name = '';
  last_name = '';
  old_password = '';
  new_password_2 = '';
  new_password = '';
  role_id = '';
  role = ''

  getUser = () => {
    http.get('/api/user/details').then(({ data }) => {
      if (data.code !== 200) {
        alert('Error : '+ data.msg);
        return;
      }
      const user = data.data || '';
      this.username = user.username || '';
      this.email = user.email || '';
      this.first_name = user.first_name || '';
      this.last_name = user.last_name || '';
      this.password = user.password_md5 || '';
      this.password2 = user.password_md5 || '';
      this.role_id = user.user_role_id || '';
      this.role = { 1: 'Admin', 2: 'Case Officer', 3: 'Intern' }[this.role_id]
    });
  }
  updateUser = () => {
    http.post('/api/user/update', {
      'email': this.email,
      'first_name': this.first_name,
      'last_name': this.last_name
    }).then(({ data }) => {
      if (data.code === 200) {
        console.log('updated my profile');
        store.showModal = true;
      } else {
        window.alert('Error : '+ data.msg)
      }
    });
  }

  updatePassword = () => {
    var md5 = require('md5');
    let oldPass = md5(this.old_password);
    let newPass = md5(this.new_password);
    let newPass2 = md5(this.new_password_2);
    http.post('/api/my/password', {
      'old_password': oldPass,
      'new_password': newPass,
      'new_password_2': newPass2,
    }).then(({ data }) => {
      if (data.code === 200) { store.showModal = true; }
      else {
        window.alert('Error : '+ data.msg)
      }
    });
  }
}

decorate(CurrentUser, {
  id: observable,
  username: observable,
  email: observable,
  first_name: observable,
  last_name: observable,
  password: observable,
  password2: observable,
  role_id: observable,
  role: observable

});

const storeCurrentUser = new CurrentUser();
window.storeCurrentUser = storeCurrentUser
export default storeCurrentUser;