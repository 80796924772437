
const style = (theme) => {
  return {
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      fontSize: theme.spacing(8)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      margin: theme.spacing(1)
    },
    input: {
      display: 'none',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(0.4),
      marginRight: theme.spacing(0.4),
    },
    space: {
      padding: theme.spacing(1)
    },
    paper: {
      width: 'auto',
      overflow: 'auto',
      rounded: 'true',
      minHeight: 200,
    },
  };
};

export default style;