import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import storeUserList from './store_user_list';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import PopupDialog from './popup_dialog';
import Grid from '@material-ui/core/Grid';
import StyledTableRow from './styled_table_row';
import StyledTableCell from './styled_table_cell'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import Tooltip from '@material-ui/core/Tooltip';

const UserListPanel = observer(class UserListPanel extends React.Component {
  constructor(props) {
    super(props);
    storeUserList.clearSearchList();
    storeUserList.getList()
  }

  handleNameChange(event) {
    storeUserList.search_name = event.target.value;
  };

  handleEmailChange(event) {
    storeUserList.search_email = event.target.value;
  };

  render() {
    return <>
      <Typography component="h2" variant="h4" gutterBottom>
        Maintain User
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            name="name"
            label="Username"
            defaultValue={storeUserList.search_name}
            onChange={this.handleNameChange}
            InputLabelProps={{ shrink: true }}
          /></Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            defaultValue={storeUserList.search_email}
            onChange={this.handleEmailChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            onClick={() => {
              storeUserList.clearSearchList();
              storeUserList.getList()
            }}
            variant="contained"
            color="primary"
          >Search User</Button>
        </Grid>
      </Grid>
      <Paper>
        <Table>
          <TableHead>
            <TableRow >
              <StyledTableCell>User#</StyledTableCell>
              <StyledTableCell>Login ID</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>User Role</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storeUserList.list.map(row => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.id}</StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.first_name}</StyledTableCell>
                <StyledTableCell>{row.last_name}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{{'1':'Admin','2':'Officer','3':'Intern'}[row.user_role_id]}</StyledTableCell>
                <StyledTableCell>
                <Tooltip title='Delete User'>
                <IconButton color= "secondary" aria-label = 'delete' onClick={() => { storeUserList.removeUser(row.id) }}><DeleteIcon></DeleteIcon></IconButton>
                </Tooltip>
                <Tooltip title='Reset Password'>
                <IconButton aria-label = 'reset' onClick={() => { storeUserList.resetUser(row.id) }}><RestoreIcon></RestoreIcon></IconButton>
                </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div>
        {
          storeUserList.showLoadMore ?
            <Button
              onClick={() => { storeUserList.getList(true) }}
              variant="contained"
              color="primary"
            >
              Load more...
            </Button> : null
        }
        </div>
      </Paper>
      <PopupDialog title="MFMW" body="Reset password done" />
    </>
  };
})

export default UserListPanel