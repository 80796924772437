import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { pink, red, teal} from '@material-ui/core/colors';

let theme = createMuiTheme({
    spacing: 8,
    palette: {
        primary: teal,
        secondary: pink,
        error: red,
    },
    status: {
        danger: red,
    },

    overrides: {
        MuiPaper: {
            root: {
                padding: '10px',
                marginBottom: '10px',
                overflow: 'auto',
                width: 'auto',
                rounded: 'true',
            },
        },
        MuiTable:{
            
        }
    },
    
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
    }
});
theme.spacing(2); 
theme = responsiveFontSizes(theme);

export default theme;