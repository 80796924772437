import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import { storeCaseInfo } from './store_case_info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Edit, AttachMoney, Info } from "@material-ui/icons";

var asOfDate = new Date()
asOfDate = asOfDate.getDate() + '/' + asOfDate.getMonth() + '/' + asOfDate.getFullYear()

function calcSalaryPerDay() {
  try {

    if (storeCaseInfo.salaryInContract) {
      var numb = (storeCaseInfo.salaryInContract * 12) / 365;
      numb = isNaN(numb) ? 0 : numb
      return numb.toFixed(2);
    }
  } catch (e) {
    console.log('calc salary per day called  Exception ' + e)
    return 0
  }
  return 0
}


function calcRestDayPay(variable) {
  try {
    if (variable) {
      var numb = (variable * calcSalaryPerDay());
      numb = isNaN(numb) ? 0 : numb
      return numb.toFixed(2);
    }
  } catch (e) {
    console.log('calc salary per day called  Exception ' + e)
    return 0
  }
  return 0
}


function calcLSP() {
  try {
    let end_date = storeCaseInfo.contractEndDate ? new Date(storeCaseInfo.contractEndDate) : Date.now()
    let diff_ms = end_date - new Date(storeCaseInfo.contractStartDate).getTime();
    let diff_dt = new Date(diff_ms)
    let service_years = Math.abs(diff_dt.getUTCFullYear() - 1970);
    console.log('service years ' + service_years);
    var numb = 0;
    if (service_years >= 2) {
      //Following this route instead of number of days x lsp per days as MFMW members calculate this way
      //There is small difference in two methods. Using their steps will be easy to explain for them
      let service_months = diff_dt.getMonth() 
      let service_days = diff_dt.getDate() -1 
      let sev_per_year = storeCaseInfo.salaryInContract * 2 / 3
      console.log('Severence per year %s, service years %s, service months %s, service days %s',
              sev_per_year, service_years, service_months, service_days)
      numb = sev_per_year * service_years + (sev_per_year * service_months / 12) + (sev_per_year * service_days / 30 / 12)
      numb = isNaN(numb) ? 0 : numb
      console.log('LSP ' + numb)
    } else {
      numb = 0
    }
    return numb.toFixed(2);
  } catch (e) {
    console.log('calcLSP called  Exception ' + e)
    return 0
  }
}

function safeparseFloat(numb) {
  return isNaN(parseFloat(numb)) ? 0 : parseFloat(numb)
}


function calcTotalClaims() {
  try {
    let total = safeparseFloat(storeCaseInfo.wagesInArrears) + safeparseFloat(storeCaseInfo.wagesInLieuOfNotice) + safeparseFloat(calcRestDayPay(storeCaseInfo.restDays)) +
      safeparseFloat(calcRestDayPay(storeCaseInfo.annualLeaveDays)) + safeparseFloat(storeCaseInfo.statutoryHolidays) + safeparseFloat(storeCaseInfo.medicalFee) +
      safeparseFloat(storeCaseInfo.officialExpenseReimbursements) + safeparseFloat(calcLSP()) + safeparseFloat(storeCaseInfo.travelAllowance) +
      safeparseFloat(storeCaseInfo.travelAirportToDestination) + safeparseFloat(storeCaseInfo.airTicket) +
      safeparseFloat(storeCaseInfo.travelLocalTransport) + safeparseFloat(storeCaseInfo.travelTaxInsurance) + safeparseFloat(storeCaseInfo.others)
    console.log('total ' + total)
    console.log('less ' + storeCaseInfo.lessClaims)
    var numb = safeparseFloat(total) - safeparseFloat(Math.abs(storeCaseInfo.lessClaims))
    console.log('final ' + numb)
    numb = isNaN(numb) ? 0 : numb
    return numb.toFixed(2);
  } catch (e) {
    console.log('calcTotalClaims called  Exception ' + e)
    return 0
  }
}

const MonetaryClaimsForm = observer(class MonetaryClaimsForm extends React.Component {

  handleChange = () => {
    return (event) => {
      let name = event.target.name
      //console.log(name + '=' + event.target.value);
      storeCaseInfo[name] = event.target.value
    };
  };



  render() {
    return <div id='monetary_claims' >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Case ID #"
                    value={storeCaseInfo.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Client"
                    value={storeCaseInfo.givenNames + " " + storeCaseInfo.lastName}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    label="Monetary Claims As Of"
                    value={asOfDate}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    type="date"
                    name="contactStartDate"
                    label="Contract Start Date"
                    value={storeCaseInfo.contractStartDate}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="salaryInContract"
                    label="Salary in Contract $"
                    type="number"
                    value={storeCaseInfo.salaryInContract}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                  />
                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="officialExpenseReimbursements"
                    label="Official Expenses $ (reimbursement)"
                    type="number"
                    value={storeCaseInfo.officialExpenseReimbursements}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="statutoryHolidays"
                    label="Statutory Holidays $"
                    type="number"
                    value={storeCaseInfo.statutoryHolidays}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="wagesInArrears"
                    label="Wages in arrears $"
                    type="number"
                    value={storeCaseInfo.wagesInArrears}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="travelAllowance"
                    label="Traveling Allowance $"
                    type="number"
                    value={storeCaseInfo.travelAllowance}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="airTicket"
                    label="Air Passage - Ticket $"
                    type="number"
                    value={storeCaseInfo.airTicket}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="lessClaims"
                    label="Less Claims $"
                    type="number"
                    value={storeCaseInfo.lessClaims}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>


        <Grid item xs={12} md={4}>
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    type="date"
                    name="contractEndDate"
                    label="Contract End Date"
                    value={storeCaseInfo.contractEndDate}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    type="number"
                    name="salaryReceived"
                    label="Salary Received"
                    value={storeCaseInfo.salaryReceived}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="restDays"
                    label="Rest Days #"
                    type="number"
                    value={storeCaseInfo.restDays}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="annualLeaveDays"
                    label="Annual Leave Days - pro rata #"
                    type="number"
                    value={storeCaseInfo.annualLeaveDays}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="wagesInLieuOfNotice"
                    label="Wages in Lieu of Notice $"
                    type="number"
                    value={storeCaseInfo.wagesInLieuOfNotice}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={

                  <TextField
                    name="travelTaxInsurance"
                    label="Air Passage - Tax/insurance $"
                    type="number"
                    value={storeCaseInfo.travelTaxInsurance}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="travelAirportToDestination"
                    label="Air Passage - Transport from airport to destination $"
                    type="number"
                    value={storeCaseInfo.travelAirportToDestination}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <TextField
                    name="compensationComment"
                    label="Comment"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={storeCaseInfo.compensationComment}
                    onChange={this.handleChange()}
                  />
                } />
              </ListItem>

            </List>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    label="Long Service Pay/Severance Pay $"
                    type="number"
                    value={calcLSP()}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    label="Salary Per Day $"
                    autoComplete="Salary Per Day"
                    value={calcSalaryPerDay()}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    label="Rest Days $"
                    autoComplete="Rest Days $"
                    value={calcRestDayPay(storeCaseInfo.restDays)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    label="Annual Leave Pay - pro rata $"
                    autoComplete="Annual Leave Pay - pro rata $"
                    value={calcRestDayPay(storeCaseInfo.annualLeaveDays)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="medicalFee"
                    label="Medical/Dental Fee $"
                    type="number"
                    value={storeCaseInfo.medicalFee}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="travelLocalTransport"
                    label="Air Passage - Local Transport $"
                    type="number"
                    value={storeCaseInfo.travelLocalTransport}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    name="others"
                    label="Others $"
                    type="number"
                    value={storeCaseInfo.others}
                    onChange={this.handleChange()}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={4} >
          <div>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={
                  <TextField
                    disabled
                    fullWidth
                    error
                    label="Total Claims $"
                    value={calcTotalClaims()}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                } align-items="center" />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  }

});

export default MonetaryClaimsForm;

